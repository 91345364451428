<template>
    <div>
        <el-row class="title-box">
            <el-row>
                <el-col :span="12.5" style="padding-right:50px">
                    <span class="title-content">触发条件</span>
                    <span class="title-num">您添加了 {{sunriseAndSunset}} 组日出日落条件,</span>
                    <span class="title-num">{{conditionTimeNum}} 组时间条件,</span>
                    <span class="title-num"> {{conditionDateNum}} 组日期条件,</span>
                    <span class="title-num"> {{conditionDeviceNum}} 组设备条件</span>
                </el-col>
                    <el-col :span="14.8">
                        <el-tooltip class="item" effect="light" content="添加日出日落条件">
                            <el-button size="mini" type="primary" @click="addCondition(4)" icon="el-icon-alarm-clock"
                                :disabled="staDevicesShow||conditionProhibitDate"
                            >日出日落</el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" content="添加时间条件">
                            <el-button :disabled="staDevicesShow||conditionProhibitDay" size="mini" type="primary" @click="addCondition(1)" icon="el-icon-alarm-clock">时间</el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" content="添加日期条件">
                            <el-button size="mini" type="primary" @click="addCondition(2)" icon="el-icon-date"
                                :disabled="staDevicesShow||conditionProhibitDay"
                            >日期</el-button>
                        </el-tooltip>
                        <!--  :disabled="staTaShow" -->
                        <el-tooltip class="item" effect="light" content="添加设备条件">
                            <el-button size="mini" type="primary" @click="addCondition(3)" icon="el-icon-mouse">设备</el-button>
                        </el-tooltip>
                    </el-col>
                    <el-dialog
                        width="70%"
                        title="添加设备触发"
                        :visible.sync="devList.dialogVisible"
                        @close="closeAddDevDialog"
                        append-to-body
                    >
                        <AddDeviceIntoList ref="addDeviceIntoList" @getDeviceHello="getDeviceHello"  @func="getDevList"  :standSceneListData="devList" :test="test" :selectType="2" />
                    </el-dialog>
            </el-row>
            <div class="scroll">
                <el-row v-for="(item, index) in conditions" :key="index" class="scroll-content condition-box">
                    <el-col :span="21" v-if="item.jlcType === 1">
                        <LinkageConditionTime :items="item" :index="index" v-bind="$attrs" v-on="$listeners" />
                    </el-col>
                    <el-col :span="21" v-else-if="item.jlcType === 2">
                        <LinkageConditionDate :items="item" :index="index" v-bind="$attrs" v-on="$listeners" />
                    </el-col>
                    <el-col :span="21" v-else-if="item.jlcType === 3">
                        <!-- 23333333 -->
                        <LinkageConditionDevice :items="item" :index="index" v-bind="$attrs" v-on="$listeners"
                            @getConditionalDeviceOptions="getConditionalDeviceOptions"
                            @getTimeDeviceValue="getTimeDeviceValue"
                            @getRangeSelection="getRangeSelection"
                            @getSizeFather="getSizeFather"
                            :deviceDataList="item.deviceFunctionState"
                        />
                    </el-col>
                    <el-col :span="21" v-else-if="item.jlcType==4||5||6">
                        <triggerConditionSunriseSunset :items="item" :index="index" @getSunriseAndSunset="getSunriseAndSunset" @dayNightType="dayNightType"/>
                    </el-col>
                    <el-col :span="3" align="right">
                        <el-button @click="deleRow(item,index)" type="danger" size="mini"><i class="el-icon-arrow-right el-icon-delete"></i></el-button>
                    </el-col>
                </el-row>
            </div>
        </el-row>
    </div>
</template>
<script>

import LinkageConditionDate from './linkageConditionChild/LinkageConditionDate.vue'//日期条件
import LinkageConditionTime from './linkageConditionChild/LinkageConditionTime.vue'//时间条件
import LinkageConditionDevice from './linkageConditionChild/LinkageConditionDevice.vue'//设备条件
import triggerConditionSunriseSunset from './linkageConditionChild/triggerConditionSunriseSunset' // 日出日落条件
import AddDeviceIntoList from '../../../views/equipmentMatters/components/sceneManagement/addSeneComponents/addDeviceIntoList.vue'

import {hasHs} from '../assets/js/linkage.js';
import { linkageGetLinkageDevice } from '@/api/scene';
import {mapMutations,mapState} from 'vuex'
export default {
    props:{
        hsId:String | Number,
        // conditionDev:Array,
        conditions:Array,
        isDis:Object,
        // form:{
        //     type:Object,
        //     required:true
        // }
    },
    components:{
        LinkageConditionDate,
        LinkageConditionTime,
        LinkageConditionDevice,
        AddDeviceIntoList,
        triggerConditionSunriseSunset
    },
    data() {
        return {
            deviceDataList:[],
            conditionDeletionId:"", // 触发条件删除id
            dayNightId:"1", // 日出日落白天黑夜
            triggerConditionDeletion:[], // 删除id
            test:'',
            condition:this.conditions,
            // sunriseAndSunset:0,
            // conditionDateNum:0,
            // conditionTimeNum:0,
            // conditionDeviceNum:0,
            cloneIsDis:this.isDis,
            // triggerConditionButton:"",

            conditionsObj:{
                jlcType: '',// 定时类型(1: 时间, 2: 日期, 3: 设备, 4: 日出日落)
                jlcId:'',
                type:'',
                devName:'',
                startDate:null,     //开始日期
                endDate:null,       //结束日期
                startTime:null,     //开始时间
                endTime:null,       //结束时间
                deviceId:'',      //设备id
                // setStatus:{
                //     state:false,    //设备开关
                // },
                // deviceStatus:false, //设备开关
               
                // pickerOptionsStartTime: {
                //     selectableRange: '00:00:00 - 23:59:00',
                // },
                // pickerOptionsEndTime: {
                //     selectableRange: '00:00:00 - 23:59:00',
                // },
                // pickerOptionsStartDate: {},
                // pickerOptionsEndDate: {},
            },
            devList: {
                loading: false,
                dialogVisible: false,
                sceneList: [],
                devTable: [],
                hsId: this.hsId,
            },
        }
    },
    computed:{
        // triggerConditionButton(){
        //     if(this.conditionTimeNum >=0 || this.conditionDateNum >=0 || this.sunriseAndSunset >=0){
        //         console.log('进来')
        //          return true
        //     }else {
        //         console.log('出去')
        //          return false
        //     }
        // },
        ...mapState(['staDevicesShow','staTaShow']),
        conditionProhibitDate(){
            if(this.conditionTimeNum >=1 || this.conditionDateNum >=1){
                this.$store.commit('getMechaningMut',true)
                 return true
            }else {
                 this.$store.commit('getMechaningMut',this.conditionProhibitDay)
                 return false
            }
        },
        conditionProhibitDay(){
            if(this.sunriseAndSunset >=1){
                this.$store.commit('getMechaningMut',true)
                return true
            }else {
                return false
            }
        },
        // isConditionTime(){
        //     if(this.cloneIsDis.isConditionTime){
        //       return true
        //     }else{
        //         if(this.conditionTimeNum >=1){
        //             return true
        //         }else{
        //             return false
        //         }
        //     }
        // },
        // isConditionDate(){
        //     if(this.cloneIsDis.isConditionDate){
        //       return true
        //     }else{
        //         if(this.conditionDateNum >=1){
        //             return true
        //         }else{
        //             return false
        //         }
        //     }
        // },
        // --------------------
        conditionTimeNum(){
            let num = 0;
            this.conditions.forEach(item=>{
                if(item.jlcType == 1){
                    num++
                }
            })
            return num
        },
        conditionDateNum(){
            let num = 0;
            this.conditions.forEach(item=>{
                if(item.jlcType == 2){
                    num++
                }
            })
            return num
        },
        conditionDeviceNum(){
            let num = 0;
            this.conditions.forEach(item=>{
                if(item.jlcType == 3){
                    num++
                }
            })
            return num
        },
        sunriseAndSunset(){
            let num = 0;
            this.conditions.forEach(item=>{
                if(item.jlcType == 4 || item.jlcType == 5 || item.jlcType == 6){
                    num++
                }
            })
            return num
        },
    },
    //方法
    methods:{
        // ...mapMutations(['getMechaningMut']),

        getConditionalDeviceOptions({functionList,englishName,compare,index}){
            console.log(functionList,englishName,compare,index,123)
              this.conditions[index].englishName=englishName
              this.conditions[index].compare=compare
              this.conditions[index].functionList=functionList
            //   this.conditions[index].intention =''
        },
        getTimeDeviceValue({functionList,englishName,index}){
            console.log(functionList,englishName,index,456)
              this.conditions[index].englishName=englishName
              this.conditions[index].functionList=functionList
            //   this.conditions[index].intention =''
        },
        getRangeSelection({functionList,englishName,compare,index}){
            console.log(functionList,englishName,index,789)
              this.conditions[index].englishName=englishName
              this.conditions[index].functionList=functionList
               this.conditions[index].compare=compare
            //   this.conditions[index].intention =''
        },
        getSizeFather({compare,index}){
            console.log(compare,'compare')
             this.conditions[index].compare=compare
        },
        dayNightType({index,row}){
            console.log(row)
            this.conditions[index].jlcType= row
        },
        // ----------------------
        getSunriseAndSunset({sunriseSunset,conpare,index}){
            this.conditions[index].sunrise_sunset=sunriseSunset
            this.conditions[index].conpare=conpare
        },
        closeAddDevDialog(){
          Object.assign(this.$refs.addDeviceIntoList.form.params,this.$refs.addDeviceIntoList.$options.data().form.params)
        },
        getDevList(devData){
            console.log(devData,'devData')
            devData.forEach((key,index) => {
              key.isHove = false
              this.conditions.forEach((key1,index1) => {
                // if(key.jourDeviceId == key1.deviceId){
                //   key.isHove = true
                // }
              });
            });
            devData.forEach(key => {
                console.log(key,6666666);
              if(!key.isHove){
                let obj = this.$options.data().conditionsObj;
                obj.jlcType = 3;
                obj.devName = key.devNickname;
                obj.deviceId = key.jourDeviceId;
                obj.intention = "新增";
                obj.deviceFunctionState = key.deviceFunctionState;
                this.conditions.push(obj)
              }
              if(this.conditions.length>0){
                  console.log(321)
                this.$store.commit('getEquipmentMut',true)
              }
            });
        },
        // 233333333---------------------------
        getDeviceHello({Dev}){
            console.log(Dev,'Dev')
            this.deviceDataList = [...this.deviceDataList,...Dev]
        },
        // ---------------------------
        getstand(type){
            console.log(type)
            this.devList.dialogVisible = true
            this.devList.loading = true;
            this.devList.hsId = this.hsId
            this.condition.forEach(item=>{
                // console.log(item,'item')
                if(item.jlcType == type){
                //    console.log(item.deviceId)
                //    console.log(item.jourDeviceId)
                    item.jourDeviceId = item.deviceId
                    this.devList.devTable.push(item)
                }
            })
            linkageGetLinkageDevice({
                devNickname: '',
                devSn: '',
                devAuthSecret: '',
                hsIds:[this.hsId],
                selectType:2,
                selectAll: 1
                })
            .then((res) => {
                console.log(res,'res')
                this.devList.sceneList = res.data.result.filter(e=>e.deviceFunctionState);
                if (this.devList.sceneList.length < 1) {this.devList.loading = false;this.$message.warning('此场景没有设备');return}
                this.devList.loading = false;
            })
          .catch((err) => {
            this.isError(err, this);
          });
        },
        // xiugaiConditionDev(devId){

        //     let list = []
        //     this.$nextTick(()=>{
        //         for(let i in this.conditions){
        //             if(this.conditions[i].deviceId){
        //                 list.push(this.conditions[i].deviceId)
        //             }
        //         }
        //         for(let i in this.conditions){
        //             this.conditions[i].disabled = false
        //             for (let j in list) {
        //                 if(this.conditions[i].jourDeviceId === list[j]){
        //                     this.conditions[i].disabled = true
        //                 }
        //             }
        //         }
        //     })
        // },
        addCondition(type){
            // console.log(this.conditions.length)
            // this.getMechaningMut(true)
            // this.$store.commit('getMechaningMut',true)
            // console.log(type)
            if(!hasHs(this.hsId)){this.$message.warning("请选择房间后添加"); return}
            if(type == 1){
                if(this.conditionTimeNum >= 1){
                    this.$message({message: '一个群组下只能选择一个时间条件',type: 'warning'});
                    return
                };
                // this.isDis.isMechanismTime = true;
                // this.isDis.isOnceTime = true;
                // this.isDis.isPeriodTime = true;
            }else if(type == 2){
                if(this.conditionDateNum >= 1){
                    this.$message({message: '一个群组下只能选择一个日期条件',type: 'warning'});
                    return
                };
                // this.isDis.isOnceTime = true;
                //  this.isDis.isMechanismTime = true;
            }else if(type == 3){
                // if (this.roomExecutionDevice.length < 1) {this.$message({showClose: true,message: '此房间没有可触发设备',type: 'warning',});return}
                this.test = "执行设备"
                this.getstand(type)
                return
            }else if(type == 4){
                console.log(6666666)
                if(this.sunriseAndSunset >= 1){
                    this.$message({message: '一个群组下只能选择一个日出日落条件',type: 'warning'});
                    return
                };
            //   this.isDis.isMechanismTime = true;
            }
            let obj = this.$options.data().conditionsObj
            obj.jlcType = type;
            obj.intention = '新增';
            this.conditions.push(obj)
            
            // -----------------------
        },
        deleRow(type,index){
            this.conditionDeletionId=[...this.conditionDeletionId,...this.conditions.splice(index,1)]
            // console.log(this.conditionDeletionId)
            this.$emit('conditionDeletionEvent',{conditionId:this.conditionDeletionId})
            if(this.conditions.length<=0){
                this.$store.commit('getEquipmentMut',false)
            }
            // -----------
            // this.triggerConditionDeletion=[...this.triggerConditionDeletion,this.condition.splice(index,1)]
            // let triggerConditionDeletion =this.triggerConditionDeletion
            // this.$emit('triggerConditionDeletion',triggerConditionDeletion)
            // -------------------------
            // if(type === 1){
            //     this.$emit('deleCondition',)
            // }else if(type === 2){
            //     this.$emit('deleCondition',)
            // }else if(type === 3){
            //     this.$nextTick(()=>{
            //         this.xiugaiConditionDev()
            //     })
            // }else if(type === 4){
            // }

            
            //   ====================================
            this.deviceDataList.splice(index,1);
        },
    },
    created(){
        linkageGetLinkageDevice({
            devNickname: '',
            devSn: '',
            devAuthSecret: '',
            hsIds:[this.hsId],
            selectType:2,
            selectAll: 1
        }).then((res) => {
            console.log(res,'res')
            this.devList.sceneList = res.data.result;
            if (this.devList.sceneList.length < 1) {this.devList.loading = false;this.$message.warning('此场景没有设备');return}
            this.conditions.forEach(e=>{
                let bigObj = this.devList.sceneList.find(item=>e.devName == item.devNickname);
                let deviceFunctionState = bigObj?(bigObj.deviceFunctionState?bigObj.deviceFunctionState:[]):[];
                let chinesename = deviceFunctionState.find(item=>e.chinesename == item.sds_chinese_name);
                console.log(chinesename,'99999999999999999999');
                this.$set(e,`deviceFunctionState`,deviceFunctionState)
                this.$set(e,`chinesename`,chinesename)
            })
        })
        .catch((err) => {
          this.isError(err, this);
        });
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/linkage.scss';
</style>