<template>
  <el-row>
    <el-col :span="4.5" align="left" style="padding-right:10px">
      <span>触发条件：</span>
      <el-select 
       size="mini" style="width:100px;" 
       placeholder="请选择" 
       v-model="items.dayAndNight"
       @change="dayAndNightEvents"
       >
       <el-option v-for="item in dayNightSunriseSunset" :key="item.name" 
       :label="item.name" 
       :value="item.id"
       >
       </el-option>
      </el-select>
    </el-col>
    <el-col :span="10.5" style="padding-right:10px" align="left">
        开始日期：
        <el-date-picker
            v-model="items.startDate"
            type="date"
            size="mini"
            style="width:150px;"
            value-format="yyyy-MM-dd"
            placeholder="请选择开始日期"
            :picker-options="pickerOptionsStartDate"
            >
        </el-date-picker>
        至
        <el-date-picker
            v-model="items.endDate"
            type="date"
            size="mini"
            style="width:150px;"
            value-format="yyyy-MM-dd"
            placeholder="请选择结束日期"
           
            :picker-options="pickerOptionsEndDate"
            >
        </el-date-picker>
        结束日期
    </el-col>
    <el-col :span="6" v-if="items.dayAndNight==4">
       <span>日出日落：</span>
       <el-select 
       size="mini" style="width:100px;" 
       placeholder="请选择" 
       v-model="items.setSunriseSunsets"
       @change="conditionSunriseSunsetChange($event,index)"
       >
       <el-option v-for="item in sunriseAndSunset" :key="item.name" 
       :label="item.name" 
       :value="item.name"
       >
       </el-option>
       </el-select>
    </el-col>
  </el-row>
</template>
<script>
export default{
  props:{
    items:Object,
    index:Number
  },
  data(){
    return {
     dayNightSunriseSunset:[
       {
         name:"日出日落",
         id:4,
       },
       {
         name:"白天",
         id:5,
       },
       {
         name:"夜间",
         id:6
       }
     ],
     sunriseAndSunset:[
         {
           name:"日出时",
          //  data:"="
         },
         {
           name:"日出前",
          //  data:"<"
         },
         {
           name:"日出后",
          //  data:">"
         },
         {
           name:"日落时",
          //  data:"="
         },
         {
           name:"日落前",
          //  data:"<"
         },
         {
           name:"日落后",
          //  data:">"
         }
     ]
    }
  },
  methods:{
    dayAndNightEvents(row){
      console.log(row)
      this.$emit('dayNightType',{index:this.index,row})
    },
    conditionSunriseSunsetChange(row,index){
      console.log(row,index,'row,index')
      // sunrise:日出，sunset:日落
      // console.log(row,index)
      let sunriseSunset=(/^日出/.test(row)?'sunrise':'sunset');
      let conpare=(/时$/.test(row)?`=`:/后$/.test(row)?`>`:`<`)
      this.$emit('getSunriseAndSunset',{sunriseSunset,conpare,index})
    },

  },
  computed:{
        pickerOptionsStartDate(){
            let that = this;
            return {
                disabledDate(time){
                    if (that.items.endDate) {
                      return (time.getTime() > new Date(that.items.endDate).getTime() || time.getTime() < Date.now() - 8.64e7)
                    }
                    return time.getTime() < Date.now() - 8.64e7
                }
            }
        },
        pickerOptionsEndDate(){
            let that = this
            return {
                disabledDate(time){
                    if (that.items.startDate) {
                      return (time.getTime() < new Date(that.items.startDate).getTime())
                    }
                    return time.getTime() < Date.now() - 8.64e7
                }
            }
        }
  },  
}
</script>
<style scoped lang='scss'></style>