<template>
    <div>
        <el-row class="title-box">
            <el-col :span="12" >
                <span class="title-content">触发机制</span>
                <span class="title-num">您添加了 {{mechanismTimeNum}} 组时间触发</span>
                <span class="title-num"> {{mechanismDeviceNum}} 组设备触发</span>
            </el-col>
            <el-col :span="12" align="right">
                <el-tooltip class="item" effect="light" content="添加时间触发" placement="top">
                    <el-button :disabled="setMechaningShow" size="mini" type="primary" @click="addMechanism(1)"  
                    icon="el-icon-alarm-clock">时间触发</el-button>
                </el-tooltip>
                <!-- :disabled="staEquipmentShow" -->
                <el-tooltip class="item" effect="light" content="添加设备触发" placement="top">
                    <el-button  size="mini" type="primary" @click="addMechanism(2)" icon="el-icon-mouse">设备触发</el-button>
                </el-tooltip>
            </el-col>
        </el-row>
        <!-- 点击添加设备触发弹出框 -->
        <el-dialog
            width="70%"
            title="添加设备触发"
            :visible.sync="devList.dialogVisible"
            @close="closeAddDevDialog"
            append-to-body>
            <addDeviceIntoList  @getDeviceHello="getDeviceHello" ref="addDeviceIntoList" @func="getDevList"  :standSceneListData="devList" :test="test" :selectType="1" ></addDeviceIntoList>
        </el-dialog>
        <!-- ------ -->
        <div class="scroll">
            <el-row v-for="(item, index) in mechanisms" :key="'id:'+index" class="scroll-content mechanism-box">
                <el-col :span="22" v-if="item.type === 1" >
                    <LinkageMechanismTime :items="item" :isDis="isDis" :index="index" v-bind="$attrs" v-on="$listeners"
                     @sunsetSunriseChange="sunsetSunriseChange"
                     @singleTriggerDate="singleTriggerDate" 
                     @wordTriggerEvent="wordTriggerEvent" 
                     @singleTriggerType="singleTriggerType" 
                     @setWeekChange="setWeekChange"
                     @cycleTime="cycleTime"
                     @cycleTriggerType="cycleTriggerType"
                     @singleSunsetSunriseChange="singleSunsetSunriseChange"
                     />
                </el-col>
                <el-col :span="22" v-else-if="item.type === 2">
                    <LinkageMechanismDevice :items="item" :index="index"  v-bind="$attrs" v-on="$listeners" 
                    @getOptionTypeData="getOptionTypeData"
                    @getNumericTypeData="getNumericTypeData"
                    @getCompare="getCompare"
                    :deviceDataList="item.deviceFunctionState"
                    />
                    <!-- :roomExecutionDevice="roomExecutionDevice" -->
                </el-col>
                <el-col :span="2" align="right">
                    <el-button @click="deleRow(item,index)" type="danger" size="mini"><i class="el-icon-arrow-right el-icon-delete"></i></el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { linkageGetLinkageDevice,linkageGetLinkageDevice2,linkageDeviceState,scenelnstructionsRemove} from '@/api/scene';
import LinkageMechanismTime from './linkageMechanismChild/LinkageMechanismTime.vue'//时间触发
import LinkageMechanismDevice from './linkageMechanismChild/LinkageMechanismDevice.vue'//设备触发
import addDeviceIntoList from '../../../views/equipmentMatters/components/sceneManagement/addSeneComponents/addDeviceIntoList.vue'
import installationAddress from '@/components/equipmentChild/installationAddress.vue'
import {hasHs} from '../assets/js/linkage.js';
import { mapState,mapMutations } from 'vuex'
export default {
    naem:"LinkageMechanism",
    props:{
        // roomExecutionDevice:Array,
        hsId:String | Number ,
        mechanisms:Array,
        isDis:Object,
        jlId:String | Number,
    },
    components:{
        LinkageMechanismTime,
        LinkageMechanismDevice,
        addDeviceIntoList,
        installationAddress,
    },
    data() {
        return {
            deviceDataList:[],
            test:'',
            delMechanisms:[], // 触发机制 删除id
            // mechanism:this.mechanisms,
            mechanismsObj:{
                // jltId:'',  // 时间触发机制Id
                // jldId:'', // 设备触发机制Id
                // type:'',       //类型
                // deviceId:'',   //设备Id
                // devName:'',    //设备名称
                // aisle:'',      //设备通道
                // ft:'',         //设备一级类型
                // st:'',         //设备二级类型
                // subType:'',    //时间子类型: 1 => 单次, 2 => 多次
                // -----------------
                // onceDate:'',  //单次到达时间
                // time:null,    //单次时间或多次到达时间
                // date:null,   //单次开始时间
                // period:'',      //周期到达时间
                // sunday:[],     //周期星期有效
                // weekDays:[], //周期星期有效
                // ---------------
                // periodDate:'',  
                // actionList:[],
                // ---------------
                // intention:"", // 操作意图
                // onceDate:"", // 单次触发 指定时间 日期
                // time:"", // 单次触发 指定时间 时间
                // weekDays:"",// 周期触发 星期
                // jltType:"", // 机制类型（0：指定时间；1：日出时间；2：日落时间）
                // jltVariable:"",// 日出日落校准值(秒)(0=固定时间；-120=提前120秒；+120=推迟120秒)
                // jldStatus:""// 设备状态
            },
            devList: { 
                loading: false,
                dialogVisible: false,
                sceneList: [],
                devTable: [],
                hsId: this.hsId,
            },

        }
    },
    computed:{
        ...mapState(['setMechaningShow','staEquipmentShow']),
        mechanismTimeNum(){
            let num = 0;
            this.mechanisms.forEach(item=>{
                if(item.type == 1){
                    num++
                }
            })
            // console.log(num)
            return num
        },
        mechanismDeviceNum(){
            let num = 0;
            this.mechanisms.forEach(item=>{
                if(item.type == 2){
                    num++
                }
            })
            return num
        },
    },
    methods: {
        // 周期 触发类型
        cycleTriggerType({value,index}){
            // console.log(value,index,'8989')
            this.mechanisms[index].jltType =(/^指定/.test(value))?'0':'0';
            this.mechanisms[index].jltVariable =(/^指定/.test(value))?'0':'0';
        },
        // 周期时间
        cycleTime({specifiedTime,index}){
           this.mechanisms[index].time=specifiedTime
        },
        // 单次 触发类型
        singleTriggerType({value,index}){
            // console.log(value,index,'8787') 
            this.mechanisms[index].jltType =(/^指定/.test(value))?'0':'0';
            this.mechanisms[index].jltVariable =(/^指定/.test(value))?'0':'0';
        },
        // 周期 日出日落
        sunsetSunriseChange({sunsetSunriseDate,sunsetSunriseMinutes,index}){
            // console.log({sunsetSunriseDate,sunsetSunriseMinutes},'123456');
            this.mechanisms[index].jltType = (/^日出/.test(sunsetSunriseDate))?1:2;
            this.mechanisms[index].jltVariable =((/时$/.test(sunsetSunriseDate))?0:(/后$/.test(sunsetSunriseDate)?`+${sunsetSunriseMinutes}`:`-${sunsetSunriseMinutes}`))
            console.log(this.mechanisms[index].jltVariable,'8989')
        },
        // 单次 日出日落
        singleSunsetSunriseChange({singleSunsetSunriseDate,singleSunsetSunriseMinutes,index}){
            //  console.log({singleSunsetSunriseDate,singleSunsetSunriseMinutes},'123456');
             this.mechanisms[index].jltType = (/^日出/.test(singleSunsetSunriseDate))?1:2;
             this.mechanisms[index].jltVariable =((/时$/.test(singleSunsetSunriseDate))?0:(/后$/.test(singleSunsetSunriseDate)?`+${singleSunsetSunriseMinutes}`:`-${singleSunsetSunriseMinutes}`))
             console.log(this.mechanisms[index].jltVariable,'5959')
        },
        // 单次 日期
        singleTriggerDate({setDate,index}){
            // console.log(setDate,index,'8686')
            this.mechanisms[index].onceDate = setDate;
        },
        // 单次 时间
        wordTriggerEvent({specifiedTime,index}){
            this.mechanisms[index].time =specifiedTime
        },
        // 周期 星期
        setWeekChange({setWeeks,index}){
            this.mechanisms[index].weekDays=setWeeks
        },
        // --------------------------------------
        getOptionTypeData({functionList,englishName,compare,index}){
            // console.log(functionList,englishName,'123')
              this.mechanisms[index].englishName=englishName
              this.mechanisms[index].functionList=functionList
              this.mechanisms[index].compare =compare
            //   this.mechanisms[index].intention =''
        },
        getNumericTypeData({functionList,englishName,index}){
            // console.log(functionList,englishName,'321')
             this.mechanisms[index].englishName =englishName
             this.mechanisms[index].functionList =functionList
            //  this.mechanisms[index].intention =''
        },
        getCompare({compare,index}){
            // console.log(compare,'321')
             this.mechanisms[index].compare =compare
        },
        // ---------------------------------------
        getDeviceHello({Dev}){
            // console.log(Dev,'Dev')
            this.deviceDataList = [...this.deviceDataList,...Dev]
        },
        // ---------------------------------------
        closeAddDevDialog(){
            Object.assign(this.$refs.addDeviceIntoList.form.params,this.$refs.addDeviceIntoList.$options.data().form.params)
        },
        // ------------------
        // xiugaiMechanismDev(devId){
        //     console.log(devId,'99999')
        //     this.$nextTick(()=>{
        //         let aisleArr = [];
        //         for (let i in this.mechanisms) {
        //             if(this.mechanisms[i].deviceId === devId && this.mechanisms[i].aisle !== ""){
        //                 aisleArr.push(this.mechanisms[i].aisle)
        //             }
        //         }
        //         for (let i in this.mechanisms) {
        //             if(this.mechanisms[i].deviceId === devId && aisleArr.length >= 1){
        //                 for(let j in this.mechanisms[i].actionList){
        //                     this.mechanisms[i].actionList[j].disabled = false
        //                     for (let k in aisleArr) {
        //                         if(this.mechanisms[i].actionList[j].cpKeyValue === aisleArr[k]){
        //                             this.mechanisms[i].actionList[j].disabled = true
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     })
        // },
        getDevList(devData){
            devData.forEach((key) => {
              key.isHove = false
              this.mechanisms.forEach((key1) => {
                if(key.jourDeviceId == key1.deviceId){
                  key.isHove = true
                }
              });
            });
            devData.forEach(key => {
              if(!key.isHove){
                let obj = this.$options.data().mechanismsObj;
                obj.type = 2;
                obj.intention = '新增';
                obj.jldId ="";
                obj.deviceFunctionState = key.deviceFunctionState;
                obj.devName = key.devNickname;
                obj.deviceId = key.jourDeviceId
                // obj.ft = key.devFirstType
                // obj.st = key.devSecondType

                this.$options.data().mechanismsObj = obj;
                this.mechanisms.push(obj)
              }
              if(this.mechanisms.length>0){
                  console.log(321)
                this.$store.commit('getTaShowMut',true)
              }
            });
        },
        // ---------------------------------------------
        getstand(){
            this.devList.dialogVisible = true
            this.devList.loading = true;
            this.devList.hsId = this.hsId
            this.mechanisms.forEach(item=>{
               if(item.type == 2){
                  item.jourDeviceId = item.deviceId
                  this.devList.devTable.push(item)
                }
            })
            linkageGetLinkageDevice({
                devNickname: '',
                devSn: '',
                devAuthSecret: '',
                hsIds:[this.hsId],
                selectType:1,
                selectAll:1,
                // selectType:2,
            })
            .then((res) => {
                this.devList.sceneList = res.data.result.filter(e=>e.deviceFunctionState);
                if (this.devList.sceneList.length < 1) {this.devList.loading = false;this.$message.warning('此场景没有设备');return}
                this.devList.loading = false;
            })
            .catch((err) => {
                this.isError(err, this);
            });
        },
        // ----------------------------------------------
        // 时间触发&设备触发
        addMechanism(value){
            console.log(value,'value')
            if(!hasHs(this.hsId)){ this.$message.warning("请选择房间后添加"); return}
            if(value === 1){
             let obj = this.$options.data().mechanismsObj;
             obj.type = value;
             obj.intention = '新增';
             obj.jldId = "",
             this.mechanisms.push(obj);
            }else if(value === 2){
               this.test="触发设备"
               this.getstand()
               // if (this.roomExecutionDevice.length < 1) {this.$message({showClose: true,message: '此房间没有可触发设备',type: 'warning',});return}
            }
            // ---------------------------------------------
             if(this.mechanisms.length>0){
                //  console.log(this.mechanisms.length,'88888')
                  this.$store.commit('getDevicesMut',true)
             }
            // --------------------------------------------
        },
        // ------------------------------------------
        deleRow(item,index){
            // console.log(item,index,'删除')
            this.delMechanisms=[...this.delMechanisms,...this.mechanisms.splice(index,1)]
            let delMechanisms =this.delMechanisms
            // console.log(this.delMechanisms,'时间机制 删除id')
            this.$emit('deleteTimeTrigger',delMechanisms)
            // -------------------------------------------
            // console.log(this.mechanisms.length,'9999')
            if(this.mechanisms.length<=0){
                //  console.log(this.mechanisms.length,'77')
                this.$store.commit('getDevicesMut',false)
                this.$store.commit('getTaShowMut',false)
            }
            // -------------------------------------
        },
    },
    async created(){
        linkageGetLinkageDevice({
            devNickname: '',
            devSn: '',
            devAuthSecret: '',
            hsIds:[this.hsId],
            selectType:1,
            selectAll:1,
            // selectType:2,
        }).then(res=>{
            console.log(res.data.result,'878787878')
            this.devList.sceneList = res.data.result;
            if (this.devList.sceneList.length < 1) {this.devList.loading = false;this.$message.warning('此场景没有设备');return}
            this.mechanisms.forEach(e=>{
                let bigObj = this.devList.sceneList.find(item=>e.devName == item.devNickname);
                let deviceFunctionState = bigObj?(bigObj.deviceFunctionState?bigObj.deviceFunctionState:[]):[];
                console.log(deviceFunctionState,bigObj,deviceFunctionState.find(item=>e.chinesename == item.sds_chinese_name),112313213);
                let chinesename = deviceFunctionState.find(item=>e.chinesename == item.sds_chinese_name);
                this.$set(e,`deviceFunctionState`,deviceFunctionState)
                this.$set(e,`chinesename`,chinesename);
            })
        })
    },
}
</script>

<style lang="scss" scoped>
@import '../assets/linkage.scss';
</style>