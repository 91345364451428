<template>
    <el-row>
        <el-col :span="4" align="left">
            触发条件：时间条件
        </el-col>
        <el-col :span="20" align="left">
            开始时间：
        <el-time-picker
            v-model="items.startTime"
            size="mini"
            style="width:200px;"
            format="HH:mm"
            value-format="HH:mm"
            placeholder="请选择开始时间"
            @change="conditionStartTime($event)"
            :picker-options="pickerOptionsStartTime"
        >
        </el-time-picker>
            至
        <el-time-picker
            v-model="items.endTime"
            size="mini"
            style="width:200px;"
            format="HH:mm"
            value-format="HH:mm"
            placeholder="请选择结束时间"
            @change="conditionEndTime($event)"
            :picker-options="pickerOptionsEndTime"
        >
        </el-time-picker>
        结束时间
        </el-col>
    </el-row>
</template>
<script>

export default {
    props:{
        items:Object,
        index:String | Number,
        groupIndex:String | Number,
    },
    components:{

    },
    data() {
        return {
            conditionTime:{
                item:this.items,
                index:this.index,
                groupIndex:this.groupIndex
            }
        }
    },
    //计算属性
    computed:{
        pickerOptionsStartTime(){
            let that = this;
            if(that.items.endTime){
                let arrTiem = that.items.endTime.split(":");
                return this.selectStartTime(arrTiem[0],arrTiem[1])
            };
            return {
                selectableRange:'00:00:00 - 23:58:59'
            }
        },
        pickerOptionsEndTime(){
            let that = this;
            if(that.items.startTime){
                let arrTiem = that.items.startTime.split(":");
                return this.selectEndTime(arrTiem[0],arrTiem[1])
            }
            return {
                selectableRange:'00:01:00 - 23:59:59'
            }
        }
    },
    //侦听器
    watch:{

    },
    //初始化生命周期
    created() {
        
    },
    //方法
    methods: {
        selectStartTime(hour,minute){
            let arrTiem;
            if(minute === "00"){
                hour = String(Number(hour)-1).length>1?String(Number(hour)-1):"0"+String(Number(hour)-1)
                arrTiem = hour+":59"
            }else{
                minute = String(Number(minute)-1).length>1?String(Number(minute)-1):"0"+String(Number(minute)-1)
                arrTiem = hour+":"+minute
            };
            return {
                selectableRange:`00:00:00-${arrTiem + ':00'}`
            }
        },
        selectEndTime(hour,minute){
            let arrTiem;
            if(minute === "59"){
                hour = String(Number(hour)+1).length>1?String(Number(hour)+1):"0"+String(Number(hour)+1)
                arrTiem = hour+":00"
            }else{
                minute = String(Number(minute)+1).length>1?String(Number(minute)+1):"0"+String(Number(minute)+1)
                arrTiem = hour+":"+minute
            };
            return {
                selectableRange:`${arrTiem + ':00'}-23:59:59`
            }
        },

        conditionStartTime(value){
            console.log(value);
            if(value != null){
                this.items.startTime = value+":00"
            }
            // this.items.pickerOptionsEndTime.selectableRange = `${this.items.startTime?this.items.startTime:'00:00:00'}`+' - 23:59:00'
        },
        conditionEndTime(value){
            console.log(value);
            if(value != null){
                this.items.endTime = value+":00"
            }
            // this.items.pickerOptionsStartTime.selectableRange = '00:00:00 - '+`${this.items.endTime?this.items.endTime:'23:59:00'}`
        }
    },
}
</script>

<style lang="scss" scoped>

</style>