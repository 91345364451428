<template>
    <el-row>
        <el-col :span="4" align="left">
            <span>执行条件：设备条件</span>
        </el-col>
        <el-col :span="5.5" style="padding-right:10px">
            <span>设备名称：{{ items.devName }}</span>
        </el-col>
        <el-col :span="3.5" style="padding-right:10px">
           <span>触发动作：</span>
            <el-select
                v-model="items.chinesename"
                size="mini"
                placeholder="请选择"
                style="width:100px; margin-right: 10px"
                @change="devActionChange"
                value-key="sds_chinese_name"
            >
                <el-option 
                    v-for="item in this.deviceDataList" :key="item.sds_chinese_name"
                    :label="item.sds_chinese_name"
                    :value="item"
                ></el-option>
            </el-select>
        </el-col>
        <el-col :span="5.5" v-if="this.devDataHi.sds_function_type=='option'"> 
            <el-select
                v-model="items.status"
                size="mini"
                style="width:100px; margin-right: 10px"
                placeholder="请选择"
                @change="conditionalOptionsChange($event,index)"
            >
                <el-option 
                    v-for="item in this.devDataHi.sds_function_llist" :key="item.dfsc_state_name"
                    :label="item.dfsc_state_name"
                    :value="item.dfsc_state_code"
                ></el-option>
            </el-select>
        </el-col>
        <el-col :span="5.5" v-if="this.devDataHi.sds_function_type=='number'">
            <el-select
                v-model="items.setOptionRange"
                size="mini"
                style="width:100px; margin-right: 10px"
                placeholder="请选择"
            >
                <el-option 
                    v-for="(item,index) in conditionSizeRangeSelect" :key="index"
                    :label="item.name"
                    :value="item.data"
                ></el-option>
            </el-select>
            <template v-if="items.setOptionRange=='2'">
                <el-button type="primary" size="mini" @click="conditionEquipmentRange = true">范围指定</el-button>
                <el-tag>{{myValue[0]}},{{myValue[1]}}</el-tag>
                <span>{{devDataHi.sds_unitOf_measure}}</span>
            </template>
            <template v-if="items.setOptionRange=='1'">
                <template>
                    <el-select
                        size="mini"
                        placeholder="请选择"
                        style="width:100px;"
                        v-model="items.compareObj"
                        @change="getSizeChange($event,index)"
                    >
                        <el-option
                            v-for="(item, index) in getNumSize"
                            :key="index"
                            :label="item.name"
                            :value="item.data"
                        ></el-option>
                    </el-select>
                    <el-input style="width:100px;" size="mini" placeholder="请输入" 
                        v-model="items.functionListObj"
                        @change="conditionalValueChange($event,index)"
                    />
                    <span>{{devDataHi.sds_unitOf_measure}}</span>
                </template>
            </template>
        </el-col>
        <el-dialog
            title="范围指定"
            :visible.sync="conditionEquipmentRange"
            width="50%"
            append-to-body
        >
            <template>
                <div class="block">
                    <el-slider
                        @change="getRangeChange(myValue)"
                        v-model="myValue"
                        range
                        show-stops
                        :max="100">
                    </el-slider>
                </div>
                <div slot="footer">
                    <el-button type="primary" @click="scopeEvent">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </el-row>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props:{
        items:Object,
        index:String | Number,
        groupIndex:String | Number,
        deviceDataList:Array | String,
    },
    data() {
        return {
            // jlcConditionValue:"",
            devDataHi:"",
            myValue:[0,0],
            conditionEquipmentRange:false,
            conditionSizeRangeSelect:[
                {
                    name:"指定",
                    data:"1"
                },
                {
                    name:"范围",
                    data:"2"
                },
            ],
            getNumSize:[
                {
                    name:"大于",
                    data:">"
                },
                 {
                    name:"小于",
                    data:"<"
                },
                {
                    name:"等于",
                    data:"="
                }
            ],
        }
    },
    created() {
        this.devDataHi = this.items.chinesename?this.items.chinesename:'';
        // console.log(this.deviceDataList,'deviceDataList')
        //  console.log(this.devDataHi,'devDataHi')
    },
    watch:{
        'items.chinesename'(val){
            this.devDataHi = val?val:''
        }
    },
    methods: {
        devActionChange(item){
            this.devDataHi =item
        },
        conditionalOptionsChange(row,index){
            this.$emit('getConditionalDeviceOptions',{functionList:`${row}`,englishName:`${this.devDataHi.sds_english_name}`,compare:"",index:index})
        },
        conditionalValueChange(num,index){
             this.$emit('getTimeDeviceValue',{functionList:`${num}`,englishName:`${this.devDataHi.sds_english_name}`,index:index})
            // if(num < this.setDevData.sds_minimum || num > this.setDevData.sds_highest){
            //    return this.$message.warning(`${this.setDevData.sds_chinese_name}不能大于或小于${num}`)
            //       return
            // }else{
            //     this.$emit('getTimeDeviceValue',{functionList:`${num}`,englishName:`${this.setDevData.sds_english_name}`,index:index})
            // }
        },
         getSizeChange(value,index){
            this.$emit('getSizeFather',{compare:value,index:index})
        },
        getRangeChange(myValue){
            this.myValue = myValue
        },
        scopeEvent(){
            this.conditionEquipmentRange =false
            let index =this.index
            this.$emit('getRangeSelection',{functionList:`${this.myValue[0]},${this.myValue[1]}`,englishName:`${this.devDataHi.sds_english_name}`,compare:"undefined",index:index})
        }
    },
}
</script>

<style lang="scss" scoped>

</style>