<template>
    <el-row>
        <el-col :span="3.5" style="padding-right:10px" align="left">
            <span>触发机制：设备触发</span>
        </el-col>
        <el-col :span="3.5" style="padding-right:10px" align="left">
            <span>设备名称：{{ items.devName }}</span>
        </el-col>
        <el-col :span="3.5" style="padding-right:10px">
           <span>触发动作：</span>
            <el-select
                v-model="items.chinesename"
                size="mini"
                placeholder="请选择"
                style="width:100px; margin-right: 10px"
                @change="devActionChange"
                value-key="sds_chinese_name"
            >
                <el-option 
                    v-for="item in this.deviceDataList" :key="item.sds_chinese_name"
                    :label="item.sds_chinese_name"
                    :value="item"
                ></el-option>
            </el-select>
        </el-col>
        <el-col :span="5.5" v-if="this.devDataHi.sds_function_type=='option'"> 
            <el-select
                v-model="items.statusObj"
                size="mini"
                style="width:100px; margin-right: 10px"
                placeholder="请选择"
                @change="conditionalOptionsChange($event,index)"
            >
                <el-option 
                    v-for="item in this.devDataHi.sds_function_llist" :key="item.dfsc_state_name"
                    :label="item.dfsc_state_name"
                    :value="item.dfsc_state_code"
                ></el-option>
            </el-select>
        </el-col>
        <el-col :span="5.5" v-if="this.devDataHi.sds_function_type=='number'">
            <el-select
                size="mini"
                placeholder="请选择"
                style="width:100px;"
                v-model="items.compareObj"
                @change="getSizeChange($event,index)"
            >
                <el-option
                    v-for="(item, index) in getNumSize"
                    :key="index"
                    :label="item.name"
                    :value="item.data"
                ></el-option>
            </el-select>
            <el-input style="width:100px;" size="mini" placeholder="请输入" 
                v-model="items.functionListObj"
                @change="conditionalValueChange($event,index)"
            />
            <span>{{devDataHi.sds_unitOf_measure}}</span>
        </el-col>
          <el-col :span="5.5" v-if="this.devDataHi.sds_function_type=='number'">
               <el-select
                        size="mini"
                        placeholder="请选择"
                        style="width:100px;"
                        v-model="items.compareObj"
                        @change="getSizeChange($event,index)"
                    >
                        <el-option
                            v-for="(item, index) in getNumSize"
                            :key="index"
                            :label="item.name"
                            :value="item.data"
                        ></el-option>
                    </el-select>
                    <el-input style="width:100px;" size="mini" placeholder="请输入" 
                        v-model="items.functionListObj"
                        @change="conditionalValueChange($event,index)"
                    />
                    <span>{{devDataHi.sds_unitOf_measure}}</span>
          </el-col>
    </el-row>
</template>
<script>
export default {
    props:{
        items:Object,
        index:String | Number,
        deviceDataList:Array | String,
    },
    data() {
        return {
            devDataHi:"",
            getNumSize:[
                {
                    name:"大于",
                    data:">"
                },
                 {
                    name:"小于",
                    data:"<"
                },
                {
                    name:"等于",
                    data:"="
                }
            ],
            suspicion:0,
        }
    },
    created() {
        // console.log(this.items,'items')
        this.devDataHi = this.items.chinesename?this.items.chinesename:'';
        console.log(this.deviceDataList,'deviceDataList')
        console.log(this.devDataHi,'devDataHi')
    },
    watch:{
        // 'items.chinesename'(val){
        //     this.devDataHi = val?val:'';
        // }
        'items.chinesename':{
            handler(val){
                this.devDataHi = val?val:'';
                if(this.suspicion==2){
                    // console.log("你真的变心了",val);
                    this.$set(this.items,'statusObj','');
                    this.$set(this.items,'compareObj','');
                    this.$set(this.items,'functionListObj','');
                }else{
                    this.suspicion++;
                }
            },
            immediate:true,
        }
    },
    methods: {
        devActionChange(item){
            this.devDataHi =item
            console.log(this.devDataHi,'8787')
        },
        conditionalOptionsChange(row,index){
            console.log(row,index)
            this.$emit('getOptionTypeData',{functionList:`${row}`,englishName:`${this.devDataHi.sds_english_name}`,compare:"",index:index})
        },
        getSizeChange(value,index){
            this.$emit('getCompare',{compare:value,index:index})
        },
        conditionalValueChange(num,index){
             this.$emit('getNumericTypeData',{functionList:`${num}`,englishName:`${this.devDataHi.sds_english_name}`,index:index})
        },
        // IndoorTempChange(num,index){
        //     console.log(num,'num')
        //     if(num <  this.setDevData.sds_minimum || num >  this.setDevData.sds_highest){
        //        return this.$message.warning(`${ this.setDevData.sds_chinese_name}不能大于或小于${num}`)
        //           return
        //     }else{
        //         this.$emit('getNumericTypeData',{functionList:`${num}`,englishName:`${ this.setDevData.sds_english_name}`,index:index})
        //     }
        // },
    },
}
</script>

<style lang="scss" scoped>

</style>