<template>
    <div class="Part">
        <div class="input">
            <el-row ref="wngRow">
                <el-button size="mini" type="primary" @click="linkageData.linkageAddDialog =true">添加联动</el-button>
                <el-dialog title="添加联动" :visible.sync="linkageData.linkageAddDialog" width="1100px" v-dialogDrag>
                    <LinkagePanle @submitLinkage="submitLinkage" v-if="linkageData.linkageAddDialog"/>
                </el-dialog>
                <el-row style="padding-left:5px">
                  <el-form inline size="mini">
                    <el-form-item>
                        名称搜索：<el-input v-model="linkageData.params.name" @change="getLinkagePage" placeholder="搜索联动名称" size="mini" style="width:150px" clearable> </el-input>
                    </el-form-item>
                    <el-form-item>
                        位置搜索：<el-input v-model="linkageData.params.address" @change="getLinkagePage" placeholder="搜索位置" style="width:150px" size="mini" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        状态搜索：<el-select v-model="linkageData.params.state" @change="getLinkagePage" placeholder="选择状态" clearable style="width:150px"> <el-option size="mini" v-for="item in linkageData.params.statusList" :key="item.id" :label="item.value" :value="item.id"></el-option></el-select>
                    </el-form-item>
                    <el-form-item>
                        创建日期：<el-date-picker v-model="linkageData.params.insertTime" @change="getLinkagePage" placeholder="搜索年月" type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width:150px"></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        修改日期：<el-date-picker v-model="linkageData.params.updateTime" @change="getLinkagePage" placeholder="搜索年月" type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width:150px"></el-date-picker>
                    </el-form-item>
                  </el-form>
                </el-row>
            </el-row>
        </div>
        <el-table height="68vh" highlight-current-row :data="linkageData.linkageTable" v-loading="linkageData.tableLoading" @row-dblclick="dbLinkageTable" :default-sort = "{prop: 'date', order: 'descending'}">
          <el-table-column prop="jlName" label="联动名称" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="address" label="位置" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="jlInsertTime" label="创建时间" align="center" show-overflow-tooltip sortable>
          <template v-slot="{ row }">
            <span style="color:green;font-weight:bold;">
              {{row.jlInsertTime}}
            </span>
          </template>
          </el-table-column>
          <el-table-column prop="jlUpdateTime" label="修改时间" align="center" show-overflow-tooltip sortable>
          <template v-slot="{ row }">
            <span style="color:red;font-weight:bold;">
              {{row.jlUpdateTime}}
            </span>
          </template>
          </el-table-column>
          <el-table-column prop="state" label="状态" align="center" show-overflow-tooltip >
          <template v-slot="{ row }">
            <span v-if="row.jlState" style="color:green;">
              {{row.state}}
            </span>
            <span v-else style="color:red;">
              {{row.state}}
            </span>
          </template>
          </el-table-column>
          <el-table-column width="180" label="操作" align="center">
              <template v-slot="{ row }">
                  <el-button size="mini" type="danger" @click.stop="operation(0,row)" v-if="row.jlState">禁 用</el-button>
                  <el-button size="mini" type="primary" @click.stop="operation(1,row)" v-else>启 用</el-button>
              </template>
          </el-table-column>
        </el-table>
        <el-dialog title="编辑联动" :visible.sync="linkageData.linkageEditingDialog" width="1100px" v-dialogDrag>
            <LinkagePanle
              v-if="linkageData.linkageEditingDialog"
              :loading="linkageData.linkageEditingLoading"
              :hsId="linkageData.linkageTableRow.jlHsId"
              :jlId="linkageData.linkageTableRow.jlId"
              :state="linkageData.linkageTableRow.state"
              :contrast="linkageData.contrastTable"
              @submitLinkage="submitLinkage"
              ref="editingLinkagePanle"/>
        </el-dialog>
        <div class="block">
          <el-pagination
            @size-change="getLinkagePage($event,'limit')"
            @current-change="getLinkagePage($event,'cursor')"
            :current-page="linkageData.params.cursor"
            :page-sizes="[5, 10, 20, 40]"
            :page-size="linkageData.params.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="linkageData.params.total"
          >
          </el-pagination>
        </div>
    </div>
</template>
<script>
import LinkagePanle from '@/components/linkage/LinkagePanle.vue'
import { devAisle,deepClone,findElem,tiemSplit } from '@/components/linkage/assets/js/linkage.js'
import {
  linkagePage,//表格接口
  linkageUpdateState,//注销和启动接口
  linkageGetLinkage,//情景管理双击表
} from '../../../src/api/scene.js';//情景接口
export default {
    name:"Linkage",
    components:{
        LinkagePanle,
    },
    data() {
        return {
            linkageData:{
                tableLoading:false,
                linkageAddDialog:false,
                linkageEditingDialog:false,
                linkageEditingLoading:false,
                linkageTableRow:{},
                linkageTable:[],
                contrastTable:[],
                // ？？？？？？？
                params:{
                    state:'',//状态搜索
                    name:'',//名称搜索
                    address: '', //位置搜索
                    insertTime:'',//创建日期搜索
                    updateTime:'',//修改日期搜索
                    cursor: 1, // 当前页数
                    limit: 5, // 一页需要显示几条数据
                    total: 0,  // 一共多少条数据
                    statusList:[{id:1,value:"启用"},{id:0,value:"禁用"}],//状态
                }, 
            },
        }
    },
    //创建后
    created() {
        this.getLinkagePage();
    },
    //更新后
    updated() {
        this.tableHeight = window.innerHeight - this.$refs.wngRow.$el.offsetHeight - 150;
    },
    methods: {
        submitLinkage(){
          this.linkageData.linkageAddDialog =false;
          this.linkageData.linkageEditingDialog =false;
          this.getLinkagePage()
          if(Object.keys(this.linkageData.contrastTable).length > 0){
            this.getLinkageGetLinkage(this.linkageData.linkageTableRow)
          }
        },
        dbLinkageTable(row){//双击表格
          // console.log(row,'双击');
          this.linkageData.linkageTableRow = row;
          this.linkageData.linkageEditingDialog = true;
          this.linkageData.linkageEditingLoading = true;
          this.$nextTick(()=>{
            this.$refs.editingLinkagePanle.linkagePanle.isLinkageMechanism = true;
            this.$refs.editingLinkagePanle.linkagePanle.isEdit=true;
            this.$refs.editingLinkagePanle.linkagePanle.affiliation=row.address;
            this.$refs.editingLinkagePanle.linkagePanle.linkageNmae=row.jlName;
            // this.$refs.editingLinkagePanle.linkagePanle.isLinkageMechanism = true;
          })
          this.getLinkageGetLinkage(row)
        },
        // 321
        getLinkageGetLinkage(row){
          // console.log(row)
          linkageGetLinkage({jlId:row.jlId}).then((res)=>{
            // this.linkageData.contrastTable = res.data.result
            let timeMechanisms = [];  // 时间机制
            res.data.result.timeMechanisms.forEach((item,index)=>{
                // console.log(item,'item')
                let mechanismsObj = this.$refs.editingLinkagePanle.$refs.LinkageMechanism.$options.data().mechanismsObj;//每条触发机制
                // deviceMechanisms 设备触发
                // timeMechanisms 时间触发
                // jltId:时间机制主键   
                // jldId:设备机制主键
                let [...weeks] =item.jltWeekDays?item.jltWeekDays:""; 
                mechanismsObj.type = 1;
                mechanismsObj.jltId =item.jltId
                mechanismsObj.subType = item.jltTimeType;  // jltTimeType 1单次触发 2周期触发
                mechanismsObj.jltType = item.jltType; // jltType 0指定时间 1日出时间 2日落时间
                mechanismsObj.jltVariable = item.jltVariable; // 日出日落校准值
                mechanismsObj.weekDays = item.jltWeekDays; // 星期
                mechanismsObj.onceDate = `${item.jltDate==undefined?item.jltDate="":item.jltDate}`; // 日期
                mechanismsObj.time = item.jltTime ?item.jltTime.slice(0,-3):""; // 时间
                mechanismsObj.setTriggerType=item.jltType==0?'指定时间':'日出日落';
                mechanismsObj.specifiedTime=item.jltTime;
                mechanismsObj.singleSunsetSunriseDate=`日${item.jltType==1?'出':'落'}${item.jltVariable==0?'时':item.jltVariable<0?'前':'后'}`;
                let jltVariable = item.jltVariable<0?-item.jltVariable:item.jltVariable;
                mechanismsObj.singleSunsetSunriseMinutes=jltVariable/60;
                mechanismsObj.sunsetSunriseMinutes=jltVariable/60; 
                mechanismsObj.isChange=item.jltVariable!=0;
                mechanismsObj.specifyTimeAnddate=item.jltDate;
                mechanismsObj.setWeek = weeks
                mechanismsObj.sunsetSunriseDate=`日${item.jltType==1?'出':'落'}${item.jltVariable==0?'时':item.jltVariable<0?'前':'后'}`;
                timeMechanisms.push(mechanismsObj);
            })
            // console.log(timeMechanisms,'时间机制');
            // ------------------------------------666666666666666666666666666
            console.log(this.$refs,'898989898')
            console.log(this.$refs.editingLinkagePanle,'ref')
            // if(timeMechanisms.length > 0){this.$refs.editingLinkagePanle.isDis.isConditionTime = true};
            if(timeMechanisms.length > 0){
              console.log('321')
              {this.$refs.editingLinkagePanle.isDis.triggerConditionButton = true}
            }
            // console.log('findElem',findElem(timeMechanisms,'subType',1));
            if(findElem(timeMechanisms,'subType',1)>-1){this.$refs.editingLinkagePanle.isDis.isConditionDate = true}
            // ---------------------------------------
            // this.$refs.editingLinkagePanle.$refs.LinkageMechanism.mechanismTimeNum = timeMechanisms.length;
            // ------------------------------------------66666666666666666666666666666
            let mechanisms = [];
            let deviceMechanisms = []; // 设备机制
            res.data.result.deviceMechanisms.forEach((item,index)=>{
                let mechanismsObj = this.$refs.editingLinkagePanle.$refs.LinkageMechanism.$options.data().mechanismsObj;//每条触发机制
                
                let items =JSON.parse(item.jldStatus)
                console.log(item,'item')
                console.log(items,'items')
                mechanismsObj.type = item.type;
                mechanismsObj.jldId = item.jldId // 设备触发机制Id
                mechanismsObj.devName = item.devNickname  // 设备名称
                mechanismsObj.deviceId = item.jldDeviceId; // 设备Id
                // -------------------
                mechanismsObj.chinesename = items.chinesename
                mechanismsObj.statusObj = items.functionList
                // ----------------------
                mechanismsObj.functionListObj = items.functionList
                mechanismsObj.englishNameObj= items.englishName
                mechanismsObj.compareObj = items.compare
                // ------------------
                mechanismsObj.englishName = items.englishName
                mechanismsObj.functionList=items.functionList
                mechanismsObj.compare = items.compare
                // ------------------
                deviceMechanisms.push(mechanismsObj);
            })
            // console.log(deviceMechanisms,'触发机制')
            // --------------------------------------------
            // this.$refs.editingLinkagePanle.$refs.LinkageMechanism.mechanismDeviceNum = deviceMechanisms.length;
            mechanisms = [...deviceMechanisms,...timeMechanisms]
           //  ---------------------
           //  this.$refs.editingLinkagePanle.mechanisms.forEach(e=>{
           //     console.log(e,'e')
           //     let chinesename =e.chinesename
           //     console.log(chinesename,'chinesename')
           //     this.$set(e,`chinesename`,chinesename)              
           //   })
           // -----------------------------
            // console.log(mechanisms,this.linkageData.contrastTable,4567892)
            this.linkageData.contrastTable.mechanisms = JSON.parse(JSON.stringify(mechanisms))
            // console.log( this.linkageData.contrastTable.mechanisms,'66666')
            this.$refs.editingLinkagePanle.mechanisms = mechanisms//触发机制 LinkageMechanism
            console.log('coming');
            // if(this.$refs.editingLinkagePanle.$refs.LinkageMechanism){
            //   console.log(`sceneList`,mechanisms,666666);
            //   mechanisms.forEach((item,index)=>{
            //     let sceneList = (this.$refs.editingLinkagePanle.$refs.LinkageMechanism.devList.sceneList);
            //     console.log(1234,sceneList,this.$refs.editingLinkagePanle.mechanisms,this.$refs.editingLinkagePanle.$refs.LinkageMechanism.devList.sceneList,index);
            //     this.$refs.editingLinkagePanle.mechanisms.forEach(e=>{
            //       let bigObj = sceneList.find(item=>e.devName == item.devNickname);
            //       console.log(bigObj,'8898989')
            //       let deviceFunctionState = bigObj?(bigObj.deviceFunctionState?bigObj.deviceFunctionState:[]):[];
            //       console.log(deviceFunctionState,deviceFunctionState.find(item=>e.chinesename == item.sds_chinese_name));
            //       let chinesename = deviceFunctionState.find(item=>e.chinesename == item.sds_chinese_name);
            //       this.$set(e,`deviceFunctionState`,deviceFunctionState)
            //       // this.$set(e,`chinesename`,chinesename);
            //     })
            //   })
            //   console.log("==============================");
            // }
            // ----------------------------------------------------
            let group = [];  //群组
            // let roomPassivityDevice = this.$refs.editingLinkagePanle.selectAddress.roomPassivityDevice;
            res.data.result.group.forEach((item,groupIndex)=>{
               console.log(item,'54321')
              let groupObj = this.$refs.editingLinkagePanle.$options.data().groupObj; //每条组群
              // console.log(roomPassivityDevice);
              // let groupNum = this.$refs.editingLinkagePanle.groupNum;
              // let isDis = this.$refs.editingLinkagePanle.isDis;
              let groupNameIndex = groupIndex+1
              groupObj.name = `群组${groupNameIndex}`;
              // console.log(groupObj.name)
              // console.log(this.$refs.editingLinkagePanle.groupNum)
              groupObj.id = groupNameIndex;
              groupObj.isDis = this.$refs.editingLinkagePanle.isDis;
              // this.$nextTick(()=>{
              //   groupObj.conditionDev = deepClone(roomPassivityDevice);
              //   groupObj.actionDev = deepClone(roomPassivityDevice);
              // })

              // console.log(groupObj);
              group.push(groupObj)
              this.$refs.editingLinkagePanle.groupValue = `群组${groupNameIndex}`
              // console.log(this.$refs.editingLinkagePanle.groupValue)

                // ---------------------------------------------------
                let conditions = [];  //触发条件
                item.conditions.forEach((key ,index)=>{
                let obj = {};
                let keys=JSON.parse(key.jlcConditionValue)
                obj.jlcId = key.jlcId //  联动条件主键
                obj.jlcType = key.type; // 定时类型(1: 时间, 2: 日期, 3: 设备, 4: 日出日落)
                obj.startDate=keys.startDate // 开始日期
                obj.endDate=keys.endDate // 结束日期
                obj.startTime=keys.startTime // 开始时间
                obj.endTime=keys.endTime // 结束时间
                obj.sunrise_sunset=keys.sunrise_sunset // 日出日落
                obj.conpare=keys.conpare // 日出日落范围
                obj.devName=key.devNickname // 设备名称
                obj.dayAndNight=key.type
                // -----------------
                obj.setSunriseSunsets =`${keys.sunrise_sunset=='sunrise'?"日出":"日落"}${keys.compare=='='?'时':keys.compare=='>'?'后':'前'}`
                // -----------------
                obj.setOptionRange = />|<|=/.test(keys.compare)?'1':'2'
                // -----------------
                obj.chinesename=keys.chinesename
                obj.status =keys.status
                // -----------------
                obj.functionListObj=keys.functionList
                obj.compareObj=keys.compare
                // -----------------
                obj.functionList =keys.functionList
                obj.englishName=keys.englishName
                obj.compare=keys.compare
                // ----------------
                conditions.push(obj);
              })
              // console.log(conditions,'触发条件')
              // ---------------------------------------------------
              // console.log('conditions1',findElem(conditions,'type',1));
              // console.log('conditions2',findElem(conditions,'type',2));
              // if(findElem(conditions,'type',1)>-1){this.$refs.editingLinkagePanle.isDis.isMechanismTime = true}
              // if(findElem(conditions,'type',2)>-1){this.$refs.editingLinkagePanle.isDis.isOnceTime = true}
              // this.$refs.editingLinkagePanle.$refs.LinkageCondition[0].conditionsObj = conditions.length
              // --------------------------------------------------
              groupObj.conditions = conditions;
              let scene = [];  //场景动作
              item.scene.forEach(key=>{
                let obj = {};
                obj.type = key.type;
                obj.sceneId = key.jsId;
                obj.sceneName = key.jscName;
                scene.push(obj);
              })
              let devAction = [];  //设备动作
              // console.log(item.instructions);
              item.instructions.forEach((key ,index)=>{
                  // let conditionsObj = this.$refs.editingLinkagePanle.$refs.LinkageCondition[0].$options.data().conditionsObj
                let obj = {};
                let jsiSetStatus = JSON.parse(key.jsiSetStatus)
                obj.type = key.type;
                obj.deviceId = key.jsiDeviceId;
                obj.state = jsiSetStatus.state;
                delete jsiSetStatus.state
                // obj.setStatus = jsiSetStatus;
                obj.devName = key.devNickname
                obj.jsiId = key.jsiId
                obj.st = key.devSecondType
                devAction.push(obj);
              })
              
              groupObj.instructions = [...scene,...devAction]//执行动作
              // this.$nextTick(()=>{
              //   if(isScene){
              //     this.$refs.editingLinkagePanle.$refs.LinkageAction[0].actionSceneNum = 1
              //   }
              //   this.$refs.editingLinkagePanle.$refs.LinkageAction[0].actionDeviceNum = devAction.length
              //   this.$refs.editingLinkagePanle.$refs.LinkageCondition[0].conditionDeviceNum = conditionDeviceNum
              //   this.$refs.editingLinkagePanle.$refs.LinkageCondition[0].conditionTimeNum = conditionTimeNum
              //   this.$refs.editingLinkagePanle.$refs.LinkageCondition[0].conditionDateNum = conditionDateNum
              // })
            })
            this.linkageData.contrastTable.group = JSON.parse(JSON.stringify(group))
            this.$refs.editingLinkagePanle.group = group
            if(this.$refs.editingLinkagePanle.$refs.LinkageCondition){
              group.forEach((item,index)=>{
                let sceneList = (this.$refs.editingLinkagePanle.$refs.LinkageCondition[index].devList.sceneList);
                this.$refs.editingLinkagePanle.group[index].conditions.forEach(e=>{
                  let bigObj = sceneList.find(item=>e.devName == item.devNickname);
                  console.log(bigObj,'8898989')
                  let deviceFunctionState = bigObj?(bigObj.deviceFunctionState?bigObj.deviceFunctionState:[]):[];
                  let chinesename = deviceFunctionState.find(item=>e.chinesename == item.sds_chinese_name);
                  this.$set(e,`deviceFunctionState`,deviceFunctionState)
                  this.$set(e,`chinesename`,chinesename);
                })
              })
            }
            this.linkageData.linkageEditingLoading = false;
          }).catch((err)=>{
            this.isError(err,this)
          })
        },
        getLinkagePage($e,type){//表格数据
          type&&(this.linkageData.params[type]=$e);
          this.linkageData.tableLoading = true;
          linkagePage({
            cursor:this.linkageData.params.cursor,
            limit:this.linkageData.params.limit,
            address:this.linkageData.params.address,
            insertDate:this.linkageData.params.insertTime || '',
            updateDate:this.linkageData.params.updateTime || '',
            name:this.linkageData.params.name,
            state:this.linkageData.params.state,
          }).then((res)=>{
            this.linkageData.linkageTable = res.data.result.records
            this.linkageData.params.total = res.data.result.total
            for (let i in this.linkageData.linkageTable) {
                let linkageRow = this.linkageData.linkageTable[i]
                let address = `${linkageRow.hsAddCommunity?linkageRow.hsAddCommunity:' '}`+`\u3000`+`${+linkageRow.hsAddFloor?linkageRow.hsAddFloor:' '}`+`\u3000`+`${linkageRow.hsAddDoorplateno?linkageRow.hsAddDoorplateno:' '}`
                this.$set(linkageRow,'address',address)
                let state = linkageRow.jlState ? '启用':'禁用'
                this.$set(linkageRow,'state',state)
            }
            this.linkageData.tableLoading = false;
          }).catch((err)=>{
            this.linkageData.tableLoading = false;
            this.isError(err,this)
          })
        },
        operation(state,row){//注销and启动
          let params ={
            jlId:row.jlId,
            state:state
          };
          linkageUpdateState(params).then((res)=>{
            console.log(res)
            this.getLinkagePage()
          }).catch((err)=>{
            this.isError(err,this)
          })
        }
    },
}
</script>
<style lang="scss">
.block {
  text-align: center;
}
</style>