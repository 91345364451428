<template>
    <div>
        <el-row class="title-box">
            <el-col :span="12">
                <span class="title-content">执行动作</span>
                <span class="title-num">您添加了 {{ actionDeviceNum }} 组设备动作,</span>
                <span class="title-num"> {{ actionSceneNum }} 组场景动作</span>
            </el-col>
            <el-col :span="12" align="right">
                <!-- <el-button size="mini" type="primary" @click="addAction(1)">添加场景动作</el-button>
                <el-button size="mini" type="primary" @click="addAction(2)">添加设备动作</el-button> -->
                <el-tooltip class="item" effect="light" content="添加场景动作" placement="top">
                    <el-button size="mini" type="primary" @click="addAction(1)" :disabled="isActionScene" icon="el-icon-menu">场景动作</el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="light" content="添加设备动作" placement="top">
                    <el-button size="mini" type="primary" @click="addAction(2)" :disabled="isActionDev" icon="el-icon-mouse">设备动作</el-button>
                </el-tooltip>
                <el-dialog
                    width="70%"
                    title="添加设备触发"
                    :visible.sync="devList.dialogVisible"
                    @close="closeAddDevDialog"
                    append-to-body>
                    <AddDeviceIntoList ref="addDeviceIntoList" @func="getDevList" :standSceneListData="devList" :test="test" :selectType="2" />
                </el-dialog>
                <el-dialog
                    width="70%"
                    title="添加场景动作"
                    :visible.sync="sceneData.dialogVisible"
                    @close="closeAddSceneDialog"
                    append-to-body>
                    <AddSceneAction ref="addSceneAction" @func="getSceneData" :standSceneListData="sceneData"/>
                </el-dialog>
            </el-col>
        </el-row>
        <div class="scroll">
            <el-row v-for="(item, index) in instructions" :key="'id:'+index" class="scroll-content action-box">
                <el-col :span="21" v-if="item.type === 1">
                    <LinkageActionScene :items="item"  v-bind="$attrs" v-on="$listeners"/>
                    <!-- :roomSceneList="roomSceneList" -->
                </el-col>
                <el-col :span="21" v-else-if="item.type === 2">
                    <LinkageActionDevice :items="item" @xiugaiActionDev="xiugaiActionDev" :actionDev='actionDev' v-bind="$attrs" v-on="$listeners" />
                </el-col>
                <el-col :span="3" align="right">
                    <el-button @click="deleRow(item.type,index)" type="danger" size="mini"><i class="el-icon-arrow-right el-icon-delete"></i></el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>


import LinkageActionDevice from './linkageActionChild/LinkageActionDevice.vue'//设备动作
import LinkageActionScene from './linkageActionChild/LinkageActionScene.vue'//场景动作
import AddSceneAction from './linkageActionChild/addSceneAction.vue'//添加场景动作
import AddDeviceIntoList from '../../../views/equipmentMatters/components/sceneManagement/addSeneComponents/addDeviceIntoList.vue'

import { hasHs } from '../assets/js/linkage.js';
import { linkageGetLinkageDevice,linkageGetLinkageScene } from '@/api/scene';

export default {
    props:{
        hsId:String | Number,
        actionDev:Array,
        // roomSceneList:Array,
        instructions:Array,
    },
    components:{
        LinkageActionDevice,
        LinkageActionScene,
        AddDeviceIntoList,
        AddSceneAction,
    },
    data() {
        return {
            isActionScene:false,
            isActionDev:false,
            instruction:this.instructions,
            
            // actionDeviceNum:0,
            // actionSceneNum:0,
            test:'',
            instructionsObj:{
                jlrId:'',
                jsiId:'',
                sceneId:'',
                deviceId:'',
                state:false,
                setStatus:{},
                type:'',
                st:'',
                ft:'',
                sceneName:'',
                devName:'',
            },
            devList: {
                loading: false,
                dialogVisible: false,
                sceneList: [],
                devTable: [],
                hsId: this.hsId,
            },
            sceneData: {
                loading: false,
                dialogVisible: false,
                sceneList: [],
                devTable: [],
                hsId: this.hsId,
            },
        }
    },
    //计算属性
    computed:{
        actionSceneNum(){
            let num = 0;
            this.instructions.forEach(item=>{
                if(item.type == 1){
                    num++
                }
            })
            return num
        },
        actionDeviceNum(){
            let num = 0;
            this.instructions.forEach(item=>{
                if(item.type == 2){
                    num++
                }
            })
            return num
        },
    },
    //侦听器
    watch:{

    },
    //初始化生命周期
    created() {
        console.log(this.instructions[0],this.instructions[0].setStatus);
    },
    //方法
    methods: {
        closeAddDevDialog(){
            Object.assign(this.$refs.addDeviceIntoList.form.params,this.$refs.addDeviceIntoList.$options.data().form.params)
        },
        closeAddSceneDialog(){
            Object.assign(this.$refs.addSceneAction.form.params,this.$refs.addSceneAction.$options.data().form.params)
        },
        getDevList(devData){
            devData.forEach((key,index) => {
              key.isHove = false
              this.instructions.forEach((key1,index1) => {
                if(key.jourDeviceId == key1.deviceId){
                  key.isHove = true
                }
              });
            });
            devData.forEach(key => {
                console.log(key);
              if(!key.isHove){
                let obj = this.$options.data().instructionsObj;
                obj.type = 2;
                obj.devName = key.devNickname;
                obj.ft = key.devFirstType
                obj.st = key.devSecondType
                obj.deviceId = key.jourDeviceId
                obj.intention = '新增';
                this.instructions.push(obj)
              }
            });
            console.log(this.instructions)
        },
        getSceneData(sceneData){
            sceneData.forEach((key,index) => {
              key.isHove = false
              this.instructions.forEach((key1,index1) => {
                if(key.jsId == key1.sceneId){
                  key.isHove = true
                }
              });
            });
            sceneData.forEach(key => {
                console.log(key);
              if(!key.isHove){
                let obj = this.$options.data().instructionsObj;
                obj.type = 1;
                obj.intention = '新增';
                obj.sceneName = key.jscName;
                obj.sceneId = key.jsId
                this.instructions.push(obj)
              }
            });
            console.log(this.instructions)
        },
        getstand(type){
            // this.standSceneListData.devTable = this.$refs.devTable.tableData;
            this.devList.dialogVisible = true
            this.devList.loading = true;
            this.devList.hsId = this.hsId
            this.instructions.forEach(item=>{
                if(item.type == type){
                    item.jourDeviceId = item.deviceId
                    this.devList.devTable.push(item)
                }
            })
            linkageGetLinkageDevice({
                devNickname: '',
                devSn: '',
                devAuthSecret: '',
                hsIds:[this.hsId],
                selectType:2
                })
            .then((res) => {
                this.devList.sceneList = res.data.result;
                if (this.devList.sceneList.length < 1) {this.devList.loading = false;this.$message.warning('此场景没有设备');return}
                this.devList.loading = false;
            }).catch((err) => {
                this.isError(err, this);
            });
        },
        getSceneAction(type){
            this.sceneData.dialogVisible = true
            this.sceneData.loading = true;
            this.sceneData.hsId = this.hsId
            this.instructions.forEach(item=>{
                if(item.type == type){
                    this.sceneData.devTable.push(item)
                }
            })
            console.log(this.sceneData.devTable);
            linkageGetLinkageScene({
                sceneName:'',
                sceneAddress:'',
                // hsIds:[this.hsId],
                hsIds:[this.hsId],
                })
            .then((res) => {
                this.sceneData.sceneList = res.data.result;
                if (this.sceneData.sceneList.length < 1) {this.sceneData.loading = false;this.$message.warning('此场景没有设备');return}
                this.sceneData.loading = false;
            }).catch((err) => {
                this.isError(err, this);
            });
        },
        xiugaiActionDev(devId){
            let list = []
            this.$nextTick(()=>{
                for(let i in this.instructions){
                    if(this.instructions[i].deviceId){
                        list.push(this.instructions[i].deviceId)
                    }
                }
                for(let i in this.actionDev){
                    this.actionDev[i].disabled = false
                    for (let j in list) {
                        if(this.actionDev[i].jourDeviceId === list[j]){
                            this.actionDev[i].disabled = true
                        }
                    }
                }
            })
        },
        addAction(type){
            if(type === 1){
                this.test = "场景动作"
                this.getSceneAction(type)
                return
            }else if(type === 2){
                if(this.actionDeviceNum >= 10 || this.isActionDev){
                    this.$message({showClose: true,message: '设备动作只能设置十个',type: 'warning',});
                    this.isActionDev = true;
                };
                this.test = "执行设备"
                this.getstand(type)
                return
            }
            let obj = this.$options.data().instructionsObj
            obj.type = type;
            this.instructions.push(obj)
        },
        deleRow(type,index){
            if(type === 1){
                this.isActionScene = false;
            }else if(type === 2){
                this.isActionDev = false;
                this.$nextTick(()=>{
                    this.xiugaiActionDev()
                })
            }
            this.instruction.splice(index,1)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../assets/linkage.scss';
</style>