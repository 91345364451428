<template>
  <el-row>
    <el-col :span="3.8" style="padding-right:10px">
      <span>触发机制：时间触发</span>
    </el-col>
    <el-col :span="4.8" style="padding-right:10px">
      <span>触发方式：</span>
      <el-select 
      size="mini" style="width:100px;"
      placeholder="请选择"
      v-model="items.subType"
      @change="changeMechanismSubType">
       <el-option 
       v-for="item in timeList" :key="item.subType"
       :label="item.label" :value="item.subType" 
       :disabled="item.disabled"
       >
       </el-option>
      </el-select>
    </el-col>
    <template v-if="items.subType==1">
        <el-col :span="5.5" style="padding-right:10px">
          <span>选择日期：</span>
           <el-date-picker  
           size="mini" style="width:130px;"
           placeholder="选择日期" 
           type="date" 
           :picker-options="{disabledDate(time) {return time.getTime() < Date.now() - 8.64e7;}}"
           @change="singleTriggerDate"  
           v-model="items.specifyTimeAnddate">
           </el-date-picker>
        </el-col>
        <el-col :span="10.5">
          <span>触发类型：</span>
          <el-select 
          size="mini" style="width:100px;"
          placeholder="触发类型"   
          v-model="items.setTriggerType"  
          @change="singleTriggerType($event)" >
           <el-option 
           v-for="item in triggerMode" :key="item.value"
           :label="item.name" :value="item.value"
           >
           </el-option>
          </el-select>
          <template v-if="items.setTriggerType=='指定时间'">
            <el-time-picker 
            size="mini" style="width:120px;"
            format="HH:mm"
            value-format="HH:mm"
            v-model="items.specifiedTime" 
            placeholder="选择时间"
            @change="wordTriggerEvent">
            </el-time-picker>
          </template>
          <template  v-if="items.setTriggerType=='日出日落'">
             <el-select size="mini" style="width:120px;" 
             placeholder="请选择"
             v-model="items.singleSunsetSunriseDate"
             @change="singleSunsetSunriseChange"  >
              <el-option 
              v-for="item in sunsetSunrise" :key="item.data" 
              :label="item.name" :value="item.name">
              </el-option>
             </el-select>
             <template v-if="items.isChange">
               <el-input size="mini"  style="width:100px;"  
               placeholder="请输入分钟" 
               v-model="items.singleSunsetSunriseMinutes"
               @blur="singleCalibrationValue" ></el-input>
               <span>分钟</span>
             </template>
          </template>
        </el-col>
    </template>
    <template v-else>
         <el-col :span="5.5" style="padding-right:10px">
           <span>选择星期：</span>
            <el-select
            size="mini"
            v-model="items.setWeek"
            multiple
            collapse-tags
            style="width:135px;"
            placeholder="默认全选"
            @change="setWeekChange"
            >
             <el-option
             v-for="item in cities"
             :key="item.week"
             :label="item.name"
             :value="item.week"
             >
             </el-option>
            </el-select>
         </el-col>
         <el-col :span="10.5">
          <span>触发类型：</span>
          <el-select 
          size="mini" style="width:100px;" 
          @change="cycleTriggerType"
          v-model="items.setTriggerType"  
          placeholder="请选择触发方式" 
          >
           <el-option v-for="item in triggerMode" :key="item.value"
           :label="item.name" 
           :value="item.value"
           >
           </el-option>
          </el-select>
        <template v-if="items.setTriggerType=='指定时间'">
          <el-time-picker size="mini" style="width:120px;"  
          v-model="items.specifiedTime" 
          @change="cycleTime"
          format="HH:mm"
          value-format="HH:mm"
          placeholder="选择时间" 
          >
         </el-time-picker>
        </template>
        <template v-if="items.setTriggerType=='日出日落'">
          <el-select size="mini" style="width:100px;" 
          placeholder="请选择"
          v-model="items.sunsetSunriseDate"   
          @change="sunsetSunriseChange" 
          >
           <el-option v-for="item in sunsetSunrise" :key="item.data" 
           :label="item.name" 
           :value="item.name">
           </el-option>
          </el-select>
          <template v-if="items.isChange">
            <el-input 
            size="mini" style="width:100px;" 
            placeholder="请输入分钟"
            @blur="periodicCalibration" 
            v-model="items.sunsetSunriseMinutes" >
            </el-input>
            <span>分钟</span>
          </template>
        </template>
         </el-col>
    </template>
  </el-row>
</template>
<script>
export default {
    name:"LinkageMechanismTime",
    props:{
        items:Object,
        index:String | Number,
        isDis:Object,
    },
    data() {
        return {
          triggerMode:[  // 触发方式
            {
              name:"指定时间",
              value:"指定时间",
            },
             {
               name:"日出日落",
              value:"日出日落",
            }
          ],
          // 日落日出
            sunsetSunrise:[
              // 日出	时	 =
              // 日出	前	 <
              // 日出	后	 >
              // 日落	时	 =
              // 日落	前	 <
              // 日落	后	 >
              {
                name:"日出时",
                data:1,
              },
              {
                name:"日出前",
                data:2,
              },
              {
                name:"日出后",
                data:3,
              },
              {
                name:"日落时",
                data:4,
              },
              {
                name:"日落前",
                data:5,
              },
              {
                name:"日落后",
                data:6,
              },
            ],
            // isChange:false,
            timeList:[{
                label:'单次触发',
                subType:1,
                disabled:this.isDis.isOnceTime
            },{
                label:'周期触发',
                subType:2,
                disabled:this.isDis.isPeriodTime
            }],
            cities: [
              {
                name: '星期一',
                week: '1',
              },
              {
                name: '星期二',
                week: '2',
              },
              {
                name: '星期三',
                week: '3',
              },
              {
                name: '星期四',
                week: '4',
              },
              {
                name: '星期五',
                week: '5',
              },
              {
                name: '星期六',
                week: '6',
              },
              {
                name: '星期天',
                week: '7',
              },
            ], 
            // cloneIsDis:this.isDis,
            mechanismTime:{
              item:this.items,
              index:this.index,
            },
        }
    },
    methods: {
      // 周期 触发类型
      cycleTriggerType(value){
        let index =this.index
        console.log(value,index,'9999')
        this.$emit('cycleTriggerType',{value,index})
      },
      // 周期 时间
      cycleTime(){
         let {specifiedTime} =this.items
         let index =this.index
        this.$emit('cycleTime',{specifiedTime,index})
      },
      // 单次 触发类型
      singleTriggerType(value){
         let index=this.index
         console.log(value,index,'99999')
         this.$emit('singleTriggerType',{value,index})
      },
      // 单次 日期
      singleTriggerDate(value){
        let date = value
			  let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        month = month < 10 ? "0" + month : month
        day = day < 10 ? "0" + day : day;
        this.setDate =`${year}-${month}-${day}`
        let {setDate} =this
        let index=this.index
        this.$emit('singleTriggerDate',{setDate,index})
        console.log(setDate,'日期')
      },
      // 单次 时间
      wordTriggerEvent(){
        let {specifiedTime} =this.items
        let index =this.index
        this.$emit('wordTriggerEvent',{specifiedTime,index})
      },
      // 周期 星期
      setWeekChange(value){
        let setWeeks =value
        setWeeks =setWeeks.toString()
        let index =this.index
        this.$emit('setWeekChange',{setWeeks,index})
      },
      // 单次 日落日出
      singleSunsetSunriseChange(row){
        let {singleSunsetSunriseDate,singleSunsetSunriseMinutes} = this.items;
        let index =this.index
        console.log(singleSunsetSunriseDate,singleSunsetSunriseMinutes,'99999')
        this.$emit('singleSunsetSunriseChange',{singleSunsetSunriseDate,singleSunsetSunriseMinutes,index})
        if(row=='日落时'||row=='日出时'){
          this.items.isChange=false
        }else{
          this.items.isChange=true
        }
      },
      // 单次 分钟数
      singleCalibrationValue(){
        let index =this.index
        let {singleSunsetSunriseDate,singleSunsetSunriseMinutes} = this.items;
        singleSunsetSunriseMinutes = singleSunsetSunriseMinutes*60
        console.log(singleSunsetSunriseMinutes,'888')
        this.$emit('singleSunsetSunriseChange',{singleSunsetSunriseDate,singleSunsetSunriseMinutes,index})
      },
      // 周期 日出日落
      sunsetSunriseChange(row){
        let {sunsetSunriseDate,sunsetSunriseMinutes} = this.items;
        let index =this.index
        console.log(sunsetSunriseDate,sunsetSunriseMinutes,'88888')
        this.$emit('sunsetSunriseChange',{sunsetSunriseDate,sunsetSunriseMinutes,index})
        if(row=='日落时'||row=='日出时'){
          this.items.isChange=false
        }else{
          this.items.isChange=true
        }
      },
       // 周期 分钟数
      periodicCalibration(){
        let {sunsetSunriseDate,sunsetSunriseMinutes} = this.items;
         let index =this.index
        sunsetSunriseMinutes=sunsetSunriseMinutes*60
        this.$emit('sunsetSunriseChange',{sunsetSunriseDate,sunsetSunriseMinutes,index})
      },
      changeMechanismSubType(){
          console.log(this.index,'index')
          if(this.items.subType === 1){
            this.$emit('conditionBtn',{state:true,subType:1})
          }else if(this.items.subType === 2){
            this.$emit('conditionBtn',{state:true,subType:2})
          }
      },
      // ------------------
        onceArrivalTime(value){
          // 单次触发到达时间
          console.log(value,'666')
            let arr = value.split(",")
            this.items.date = arr[0]
            this.items.time = arr[1]+':00'
            console.log("date:"+this.items.date,"time:"+this.items.time)
            this.mechanismTime.isLinkageMechanism = true;
            this.$emit("isLinkageMechanism",this.mechanismTime)
        },
        periodArrivalTime(value){
          console.log(value,'999')
            this.mechanismTime.isLinkageMechanism = true;
            this.$emit("isLinkageMechanism",this.mechanismTime)
        }
    },
}
</script>

<style lang="scss" scoped>
.te /deep/input::-webkit-input-placeholder {
  color: red;
}
.te /deep/input::-moz-input-placeholder {
  color: red;
}
.te /deep/input::-ms-input-placeholder {
  color: red;
}
</style>