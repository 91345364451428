<template>
    <el-row>
        <el-col :span="4" align="left" >
            执行动作：场景动作
        </el-col>
        <el-col :span="20" align="left">
            场景名称：
            <!-- <el-select
              v-model="items.sceneId"
              size="mini"
              style="width:200px; margin-right: 10px"
              placeholder="请选择执行场景"
            >
              <el-option
                v-for="(item,index) in roomSceneList"
                :key="item.jsId"
                :label="item.jscName"
                :value="item.jsId"
              ></el-option>
            </el-select> -->
            {{ items.sceneName }}
        </el-col>
    </el-row>
</template>
<script>

export default {
    props:{
        // roomSceneList:Array,
        items:Object,
        // index:String | Number,
        // groupIndex:String | Number,
    },
    components:{
    },
    data() {
        return {
            // actionScene:{
            //     item:this.items,
            //     index:this.index,
            //     groupIndex:this.groupIndex
            // }
        }
    },
    //计算属性
    computed:{

    },
    //侦听器
    watch:{

    },
    //初始化生命周期
    created() {
        
    },
    //方法
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>