<template>
    <div class="LinkagePanle" v-loading="loading">
        <el-form align="left">
            <el-form-item style="padding-bottom: 20px;">
                <el-row class='addLinkHead'>
                    <el-col :span="6" align="left">
                        联动名称：<el-input v-model="linkagePanle.linkageNmae"  prop="linkageNmae"  size="mini" style="width: 60%" placeholder="请输入联动名称" clearable></el-input>
                    </el-col>
                    <el-col :span="6" align="center" v-if="state">
                        状态：<span :style="state=='启用'?'color:green':'color:red'" >{{ state }}</span>
                    </el-col>
                    <el-col :span="6" align="left">
                        场景位置：<el-input v-model="linkagePanle.affiliation" @click.native="selectAddressBelong('选择场景位置')" prop="affiliation" size="mini" :disabled="linkagePanle.isEdit" style="width: 60%;" placeholder="点击选择场景归属位置" readonly></el-input>
                    </el-col>
                </el-row>
            </el-form-item>
            <!-- 时间触发 -->
            <el-form-item v-if="selectAddress.selectAddressRow.hsId">
                <LinkageMechanism  ref="LinkageMechanism" :mechanisms="mechanisms" :isDis="isDis" :jlId="jlId" :hsId="selectAddress.selectAddressRow.hsId"
                 @deleteTimeTrigger="deleteTimeTrigger"
                 @isLinkageMechanism="isLinkageMechanism"
                 :contrast="contrast"
                 />
            </el-form-item>
            <!-- end -->
            <el-form-item align="right" v-if="linkagePanle.isLinkageMechanism">
                <el-button @click="addGroup" style="margin-bottom: .4rem;">添加群组</el-button>
            </el-form-item>
            <el-tabs v-model="groupValue" type="card" tab-position="top" v-if="group.length !== 0"  closable @tab-remove="removeTab">
                <el-tab-pane :label="item.name"  v-for="(item, index) in group" :key="item.id" :name="item.name" >
                    <template>
                        <el-form-item>
                            <LinkageCondition ref="LinkageCondition" :groupIndex='index' :isDis="isDis" :conditions="item.conditions" 
                            :hsId="selectAddress.selectAddressRow.hsId" @deleCondition="deleCondition"
                            @conditionDeletionEvent="conditionDeletionEvent"
                            />
                            <!-- :conditionDev="item.conditionDev"  -->
                        </el-form-item>
                        <el-form-item>
                            <LinkageAction ref="LinkageAction" :groupIndex='index' :isDis="isDis" :instructions="item.instructions"  :hsId="selectAddress.selectAddressRow.hsId"/>
                            <!-- :actionDev="item.actionDev"  -->
                            <!-- :roomSceneList="selectAddress.roomSceneList" -->
                        </el-form-item>
                    </template>
                </el-tab-pane>
            </el-tabs>
            <!-- 提交 -->
            <el-form-item v-if="selectAddress.selectAddressRow.hsId" style="text-align: right">
                <el-button type="primary" @click="addLinkSubmit">提 交</el-button>
            </el-form-item>
            <!-- end -->
        </el-form>
        <!-- 任务归属对话框开始 -->
        <SelectAddress v-if="selectAddress.selectAddressDialog" :isDialogShow="selectAddress.selectAddressDialog" :isTitle="selectAddress.selectAddressTitle" @close="selectRoomDblclick" />
        <!-- 任务归属对话框结束 -->
    </div>
</template>
<script>

import SelectAddress from '../../components/selectAddress/selectAddress.vue';//选择房间
import LinkageMechanism from './mechanism/LinkageMechanism.vue';//触发机制
import LinkageCondition from './condition/LinkageCondition.vue';//触发条件
import LinkageAction from './action/LinkageAction.vue';//执行动作
import {
  sceneList,
  standLinkageList,
  standSceneList,
  linkageKeep,
} from '../../../src/api/scene.js';
import { deepClone , tiemSplit } from './../linkage/assets/js/linkage.js'
export default {
    name:"LinkagePanle",
    components:{
        SelectAddress,
        LinkageMechanism,//触发机制
        LinkageCondition,//触发条件
        LinkageAction,//执行动作
    },
    props: {
        loading:Boolean,
        hsId:String | Number,
        jlId:String | Number,
        state:String,
        contrast:{
            type:Object | Array,
            default:null,
        }
    },
    data() {
        return {
            getConditionDelId:"",
            deleteTimeData:"", // 触发机制id
            // deleterConditionId:"", // 触发条件 
            groupValue:'',
            // groupNum:1,
            groupName:'',
            // LinkagePanleLoading:this.loading,
            linkagePanle:{
                active:0,
                linkageNmae:'',
                affiliation:'',
                isEdit:false,
                isLinkageMechanism:false,
            },
            selectAddress:{
                selectAddressDialog:false,
                selectAddressTitle:'',
                selectAddressRow:{},
                // roomSceneList:[],
                // roomExecutionDevice:[],
                // roomPassivityDevice:[],
            },
            mechanisms:[],            //触发机制
            group:[],                 //群组
            groupObj:{
                name:'',
                id:'',
                conditions:[],
                instructions:[],
                // conditionDev:[],
                // actionDev:[],
            },
            isDis:{
                // triggerConditionButton:false,
                // conditionProhibitDate:false,
                // conditionProhibitDay:false,
                isMechanismTime:false,    //触发时间禁用
                isOnceTime:false,         //单次方式禁用
                isPeriodTime:false,       //周期方式禁用
                isConditionTime:false,    //时间条件禁用
                isConditionDate:false,    //日期条件禁用
            },
        }
    },
    created() {
        if(this.hsId){
            this.selectAddress.selectAddressRow.hsId = this.hsId;
        }
    },
    watch:{
        'selectAddress.selectAddressRow.hsId':{
            handler: function(val, oldVal) {
                this.$nextTick(()=>{
                    if(!this.selectAddress.selectAddressRow.hsId){return}
                })
            },
            immediate:true
        }
    },
    methods: {
        //LinkageMechanism子组件传递过来的值
        deleCondition(){
            this.$nextTick(()=>{
                let flag = false; let flag1 = false;
                this.group.forEach((item,index) => {
                    if(flag && flag1){ console.log("都是true"); return}
                    item.conditions.forEach((item1,index)=>{
                        console.log(item1.type)
                        if(item1.type === 1){
                            flag = true;
                            return
                        }else if(item1.type === 2){
                            flag1 = true;
                            return
                        }
                    })
                });
                console.log(flag,flag1);
                if(!flag){
                    this.isDis.isMechanismTime = false;
                    this.isDis.isPeriodTime = false;
                    console.log("没有时间条件");
                }
                if(!flag1 && !flag){
                    this.isDis.isOnceTime = false;
                    console.log("没有日期条件");
                }
            })

        },
        removeTab(targetName) {
            if(this.group.length <= 1){ this.$message.warning('再减就没有啦！');return}
            let index = this.group.findIndex(k=>{
                return k.name == targetName
            })
            this.group.splice(index,1)
            this.$nextTick(()=>{
                this.group.forEach((item,i)=>{
                    item.name = `${'群组'+(i+1)}`
                })
            })
            if(this.groupValue == targetName){this.groupValue = this.group[this.group.length-1].name}
            // this.group = tabs.filter(key => key.name !== targetName);
        },
        async addGroup(){
            console.log(this.$options.data().groupObj);
            await this.$nextTick(()=>{
                let length = this.group.length+1
                let obj = this.$options.data().groupObj;
                obj.name = `群组${length}`;
                obj.id = length;
                obj.isDis = this.isDis;
                // obj.conditionDev = deepClone(this.selectAddress.roomPassivityDevice);
                // obj.actionDev = deepClone(this.selectAddress.roomPassivityDevice);
                this.groupValue = `群组${length}`
                this.group.push(obj)
                // console.log(this.group)
            })
        },
        selectAddressBelong(title){
            console.log(title,8888)
            console.log(this.linkagePanle.isLinkageMechanism)
            if(this.linkagePanle.isEdit){return}
            this.selectAddress.selectAddressDialog = true;
            this.selectAddress.selectAddressTitle = title;
            this.linkagePanle.isLinkageMechanism =true;
        },
        selectRoomDblclick(row) {
            this.selectAddress.selectAddressDialog = false;
            if(!row){return}
            this.selectAddress.selectAddressRow = row;
            this.linkagePanle.affiliation = row.address;
        },
        //触发机制
        isLinkageMechanism(data){
            console.log(data,'打印 666')
            let flag = false;
            // console.log(data,'88')
            this.$nextTick(()=>{
                for (let i in this.mechanisms) {
                    // !this.mechanisms[i].periodDate
                    if(!this.mechanisms[i].aisle || !this.mechanisms[i].onceDate ){
                        console.log('2222')
                        flag = true
                        continue
                    }
                    flag = false
                    break
                }
                this.linkagePanle.isLinkageMechanism = flag
            })
            // console.log(this.linkagePanle.isLinkageMechanism);
        },
        // 触发机制 删除id
        deleteTimeTrigger(delMechanisms){
           console.log(delMechanisms)
            // console.log(delMechanisms,'时间触发 删除id')
            this.deleteTimeData=delMechanisms 
        },
        // 触发条件 删除id
        conditionDeletionEvent({conditionId}){
            console.log(conditionId,'conditionId')
            this.getConditionDelId=conditionId
        },
        // 提交
        addLinkSubmit(){
            // ---------------------------------------------
            if(!this.linkagePanle.linkageNmae){this.$message.warning("联动名称不能为空"); return}
            if(!this.selectAddress.selectAddressRow.hsId){this.$message.warning("请选择一个房间"); return}
            //这里删除 deviceFunctionState；
            //console.log(this.mechanisms) ;return 
            //if(this.mechanisms.length<1){this.$message.warning('触发机制不能为空'); return}
            for (let i in this.mechanisms) {
                // console.log(this.mechanisms,'打印mechanisms')
                let mechanismsItem = this.mechanisms[i]
                if(mechanismsItem.type === 1){
                    if(mechanismsItem.subType === 1){
                        // console.log('222')
                        // if(!mechanismsItem.onceDate){
                        //     this.$message.warning('触发机制有-时间触发-未设置选项'); return
                        // };
                    }else if(mechanismsItem.subType === 2){
                        //  console.log('333')
                        // if(!mechanismsItem.periodDate){
                        //     this.$message.warning('触发机制有-时间触发-未设置选项'); return
                        // };
                    }
                }else if(mechanismsItem.type === 2){
                    console.log("subType2")
                    if(!mechanismsItem.deviceId || mechanismsItem.aisle === ""){
                        // this.$message.warning('触发机制有-设备触发-未设置选项'); return
                    }
                };
            };
            // 判断是否有重复的触发机制
            let checkDuplicate = this.mechanisms.map(item=>{
                return {
                    type:item.type, // 时间触发&设备触发
                    subType:item.subType, // 时间触发 单次触发 周期触发
                    weekDays:item.weekDays, // 周期触发 星期
                    onceDate:item.onceDate, // 单次触发 日期
                    time:item.time, // 单次$周期触发 指定时间
                    jltType:item.jltType, // 单次$周期 机制类型 指定&日出&日落
                    jltVariable:item.jltVariable, // 单次&周期 校准值 0 -10 +10
                    // ------------------
                    // deviceId:item.deviceId, 
                    // aisle:item.aisle,
                    // date:item.date,
                }
            })
            if(this.isCheckDuplicate(checkDuplicate)){
                this.$message.warning('有重复的触发机制');return
            }
            // -----
            //判断群组是否为空
            let groupArr = [];
            for (let i = 0; i < this.group.length; i++) {
                let item = this.group[i];
                if(item.instructions.length < 1){this.$message.warning('请为群组'+(i+1)+'设置最少一个执行动作'); return}
                for (let j = 0; j < item.conditions.length; j++) {
                    let conditionsItem = item.conditions[j];
                    if(conditionsItem.type === 1){
                        if(!conditionsItem.endTime || !conditionsItem.startTime){
                            this.$message.warning('群组'+(i+1)+'-时间条件-有未设置的值'); return
                        }
                    }else if(conditionsItem.type === 2){
                        if(!conditionsItem.endDate || !conditionsItem.startDate){
                            this.$message.warning('群组'+(i+1)+'-日期条件-有未设置的值'); return
                        }
                    };
                }
                groupArr.push({
                    conditions:item.conditions,
                    instructions:item.instructions,
                })
            }
            let deepCloneGroup = JSON.parse(JSON.stringify(groupArr))
            // console.log(deepCloneGroup,'deepCloneGroup')
            // this.contrast && Object.keys(this.contrast).length>0
            console.log('12345678')
            console.log(this.contrast)
            console.log(this.contrast && (Object.keys(this.contrast).length>0))
            if(this.contrast && Object.keys(this.contrast).length>0){
                // 触发机制
                console.log('123')
                let mechanismsMyDB = this.contrast.mechanisms.map(item=>{
                    console.log(item,'item')
                    if(item.type == 1){
                        if(item.subType == 1 ){
                            console.log('subType 1')
                            return {
                                type:item['type'],
                                jltId:item['jltId'],
                                subType:item['subType'],
                                time:item['time'],
                                onceDate:item['onceDate'],
                                jltType:item['jltType'],
                                jltVariable:item['jltVariable']
                            }
                        }else if(item.subType == 2){
                            console.log('subtype 2')
                            return {
                                type:item['type'],
                                jltId:item['jltId'],
                                subType:item['subType'],
                                time:item['time'],
                                weekDays:item['weekDays'],
                                jltType:item['jltType'],
                                jltVariable:item['jltVariable']
                            }
                        }else {
                            console.log('无subtype')
                        }
                    }else if(item.type == 2){
                        return {
                            type:item['type'],
                            jldId:item['jldId'],
                            deviceId:item['deviceId'],
                            englishName:item.englishName,
                            functionList:item.functionList,
                            compare:item.compare,
                        }
                    }else{
                        console.log('无type')
                    }
                })
                // console.log(mechanismsMyDB,'mechanismsMyDB'); return 
                // console.log(this.mechanisms,'mechanisms')
                // 触发机制调用
                mechanismsMyDB = mechanismsMyDB.filter(e=>!!e)
                console.log('123')
                this.changeIntention(mechanismsMyDB,this.mechanisms)             
                let deleteIntention = this.deleteIntention(this.deleteTimeData)
                let hhh = this.mechanisms.map(e=>{
                    let obj = JSON.parse(JSON.stringify(e));
                    delete obj.deviceFunctionState;
                    return obj;
                })
                let mechanisms = [...hhh,...deleteIntention]
                // end
                // -------------------------------------------------------
                if(this.contrast.group.length > 0){
                    let deleteConditions =this.deleteConditions(this.getConditionDelId)
                    // console.log(...deleteConditions,'deleteConditions')
                    this.contrast.group.forEach((item1,index1)=>{
                        // console.log(item1,index1,'contrast.group')
                        let hd_conditions = item1.conditions.map(item=>{
                            // console.log(hd_conditions,'hd_conditions')
                            console.log(item,'item')
                            if(item.jlcType == 1){
                                console.log('1')
                                return {
                                    jlcType:item['jlcType'],
                                    jlcId:item['jlcId'],
                                    startTime:tiemSplit(item['startTime']),
                                    endTime:tiemSplit(item['endTime']),
                                }
                            }else if(item.jlcType == 2 ||item.jlcType ==5 || item.jlcType==6 ){
                                 console.log('2,5,6')
                                return {
                                    jlcType:item['jlcType'],
                                    jlcId:item['jlcId'],
                                    startDate:item['startDate'],
                                    endDate:item['endDate'],
                                }
                            }else if(item.jlcType == 3){
                                  console.log('3')
                                  if(item.compare==undefined){
                                    return {
                                        jlcType:item['jlcType'],
                                        jlcId:item['jlcId'],
                                        functionList:item.functionList,
                                        englishName:item.englishName,
                                    }
                                  }else{
                                    return {
                                    jlcType:item['jlcType'],
                                    jlcId:item['jlcId'],
                                    // deviceId:item['devNickname'],
                                    functionList:item.functionList,
                                    englishName:item.englishName,
                                    compare:item.compare
                                   }
                                  }
                              
                            }else if(item.jlcType == 4){
                                 console.log('4')
                                 return {
                                    conpare:item.conpare,
                                    endDate:item.endDate,
                                    jlcId:item.jlcId,
                                    jlcType:item.jlcType,
                                    startDate:item.startDate,
                                    sunrise_sunset:item.sunrise_sunset,
                                    setSunriseSunsets:item.setSunriseSunsets
                                 }
                            }else if(item.jltType == 5){
                                 return {
                                    jlcType:2
                                }
                            }else if(item.jltType == 6){
                                  return {
                                    jlcType:2
                                }
                            }else {
                                console.log('无jlcType')
                            }
                        })
                        // --------------------------------------------------------------
                        let hd_instructions = item1.instructions.map(instructionsItem=>{
                            if(instructionsItem.type == 1){
                                return {
                                    type:instructionsItem['type'],
                                    sceneId:instructionsItem['sceneId'],
                                    sceneName:instructionsItem['sceneName'],
                                }
                            }else if(instructionsItem.type == 2){
                                return {
                                    type:instructionsItem['type'],
                                    devName:instructionsItem['devName'],
                                    deviceId:instructionsItem['deviceId'],
                                    jsiId:instructionsItem['jsiId'],
                                    state:instructionsItem['state'],
                                    // setStatus:instructionsItem['setStatus'],
                                }
                            }
                        })
                        // ------------------------------------------------------------
                        deepCloneGroup.forEach((item2,index2)=>{
                            // console.log(item2,index2,'deepCloneGroup')
                            let qd_conditions = item2.conditions;
                            console.log(qd_conditions,hd_conditions,'前后对比');
                            let qd_instructions = item2.instructions; 
                            // console.log(qd_conditions,'qd_conditions')
                            // console.log(qd_instructions,'instructions')
                                innerloop:
                                for(let i = 0; i<qd_conditions.length; i++){
                                    let qd_conditions_itme = qd_conditions[i];
                                    console.log(qd_conditions_itme,'qd_conditions_itme')
                                    console.log(qd_conditions_itme.intention)
                                    if(qd_conditions_itme.intention =="" && qd_conditions_itme.jlcId==""){
                                        // console.log('新增')
                                        qd_conditions[i] = {...qd_conditions_itme,intention:'新增'}
                                        continue innerloop;
                                    }else{
                                        // console.log('保持修改')
                                        for(let j = 0; j<hd_conditions.length; j++){
                                          let hd_conditions_itme = hd_conditions[j];
                                          console.log(hd_conditions_itme,'hd_conditions_itme')
                                          if(qd_conditions_itme.jlcType == hd_conditions_itme.jlcType){
                                                console.log('jlcType')
                                            if(qd_conditions_itme.jlcType == 1){
                                                console.log(1)
                                                // console.log(qd_conditions_itme,'qd_conditions_itme')
                                                // console.log(hd_conditions_itme,'hd_conditions_itme')
                                                if(qd_conditions_itme.endTime == hd_conditions_itme.endTime && qd_conditions_itme.startTime == hd_conditions_itme.startTime){
                                                    console.log('保持')
                                                    qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'保持'}
                                                    continue innerloop;
                                                }else if(qd_conditions_itme.endTime != hd_conditions_itme.endTime || qd_conditions_itme.startTime != hd_conditions_itme.startTime){
                                                    console.log('修改')
                                                    qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'修改'}
                                                    continue innerloop;
                                                }
                                            }else if(qd_conditions_itme.jlcType == 2 || qd_conditions_itme.jlcType == 5 || qd_conditions_itme.jlcType == 6 ){
                                                console.log(2,3,4)
                                                // console.log(qd_conditions_itme,'qd_conditions_itme')
                                                // console.log(hd_conditions_itme,'hd_conditions_itme')
                                                if(qd_conditions_itme.endDate == hd_conditions_itme.endDate && qd_conditions_itme.startDate == hd_conditions_itme.startDate){
                                                    // console.log('保持')
                                                    qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'保持'}
                                                    continue innerloop;
                                                }else if(qd_conditions_itme.endDate != hd_conditions_itme.endDate || qd_conditions_itme.startDate != hd_conditions_itme.startDate){
                                                    // console.log('修改')
                                                    qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'修改'}
                                                    continue innerloop;
                                                }
                                            }else if(qd_conditions_itme.jlcType == 3){
                                                console.log(3)
                                                // console.log(qd_conditions_itme,'qd_conditions_itme')
                                                // console.log(hd_conditions_itme,'hd_conditions_itme')
                                                if(qd_conditions_itme.compare!=undefined){
                                                    if(qd_conditions_itme.compare!=""){
                                                    
                                                    if(qd_conditions_itme.englishName == hd_conditions_itme.englishName
                                                     && qd_conditions_itme.functionList == hd_conditions_itme.functionList
                                                     && qd_conditions_itme.compare == hd_conditions_itme.compare 
                                                     ){
                                                        qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'保持'}
                                                         continue innerloop;
                                                    }
                                                    else if(qd_conditions_itme.englishName != hd_conditions_itme.englishName 
                                                    || qd_conditions_itme.functionList != hd_conditions_itme.functionList
                                                    || qd_conditions_itme.compare != hd_conditions_itme.compare
                                                    ){
                                                      qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'修改'}
                                                       continue innerloop;
                                                    }
                                                }else{
                                                       
                                                    if(qd_conditions_itme.englishName == hd_conditions_itme.englishName 
                                                    && qd_conditions_itme.functionList == hd_conditions_itme.functionList
                                                    ){
                                                        console.log('保持')
                                                        qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'保持'}
                                                        continue innerloop
                                                    }else if(qd_conditions_itme.englishName != hd_conditions_itme.englishName 
                                                    || qd_conditions_itme.functionList != hd_conditions_itme.functionList
                                                    ){
                                                        console.log('修改')
                                                        qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'修改'}
                                                        continue innerloop;
                                                    } 
                                                  }
                                                }else{
                                                
                                                     if(qd_conditions_itme.englishName == hd_conditions_itme.englishName
                                                     && qd_conditions_itme.functionList == hd_conditions_itme.functionList
                                                     ){
                                                          qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'保持'}
                                                    }else if(qd_conditions_itme.englishName != hd_conditions_itme.englishName 
                                                          || qd_conditions_itme.functionList != hd_conditions_itme.functionList){  
                                                          qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'修改'}
                                                    }
                                                } 
                                            }else if(qd_conditions_itme.jlcType == 4){
                                                    console.log(4)
                                                    // console.log(qd_conditions_itme,'qd_conditions_itme')
                                                    // console.log(hd_conditions_itme,'hd_conditions_itme')  
                                                if(qd_conditions_itme.endDate == hd_conditions_itme.endDate 
                                                && qd_conditions_itme.startDate == hd_conditions_itme.startDate
                                                && qd_conditions_itme.sunrise_sunset == hd_conditions_itme.sunrise_sunset
                                                && qd_conditions_itme.compare == hd_conditions_itme.compare
                                                && qd_conditions_itme.setSunriseSunsets == hd_conditions_itme.setSunriseSunsets  
                                               ){
                                                    console.log('保持')
                                                    qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'保持'}
                                                    continue innerloop;
                                                }else if(qd_conditions_itme.endDate != hd_conditions_itme.endDate 
                                                || qd_conditions_itme.startDate != hd_conditions_itme.startDate
                                                || qd_conditions_itme.sunrise_sunset !=hd_conditions_itme.sunrise_sunset
                                                || qd_conditions_itme.compare!=hd_conditions_itme.compar
                                                || qd_conditions_itme.setSunriseSunsets !=hd_conditions_itme.setSunriseSunsets  
                                                ){
                                                    console.log('修改')
                                                   qd_conditions[i] = {...qd_conditions_itme,jlcId:hd_conditions_itme['jlcId'],intention:'修改'}
                                                   continue innerloop;
                                                }
                                            }
                                          }else{
                                              console.log("jlcType-diff");
                                              this.$set(qd_conditions[i],'intention','修改');
                                          }
                                        }
                                   }
                                };
                                qd_conditions.push(...deleteConditions)
                                // ----------
                                //保持和修改状态
                                innerloop:
                                for(let i = 0; i<qd_instructions.length; i++){
                                    // console.log(qd_instructions,'qd_instructions098')
                                    // console.log(qd_instructions_item,'qd_instructions_item')
                                    let qd_instructions_item = qd_instructions[i];
                                    for(let j = 0; j<hd_instructions.length; j++){
                                        let hd_instructions_item = hd_instructions[j];
                                        if( hd_instructions_item.type == qd_instructions_item.type){
                                            if(qd_instructions_item.type == 1){
                                                if(qd_instructions_item.sceneId == hd_instructions_item.sceneId){
                                                    qd_instructions[i] = {...qd_instructions_item,intention:'保持'}
                                                    continue innerloop;
                                                }else if(qd_instructions_item.sceneId != hd_instructions_item.sceneId){
                                                    qd_instructions[i] = {...qd_instructions_item,intention:'修改'}
                                                    continue innerloop;
                                                }
                                            }else if(qd_instructions_item.type == 2){
                                                if(qd_instructions_item.deviceId == hd_instructions_item.deviceId){
                                                    console.log(this.identical(hd_instructions_item.setStatus,qd_instructions_item.setStatus))
                                                    // this.identical(hd_instructions_item.setStatus,qd_instructions_item.setStatus) &&
                                                    if(this.identical(hd_instructions_item.setStatus,qd_instructions_item.setStatus) && hd_instructions_item.state == qd_instructions_item.state){
                                                        qd_instructions[i] = {...qd_instructions_item,jsiId:hd_instructions_item['jsiId'],intention:'保持'}
                                                        continue innerloop;
                                                        // !this.identical(hd_instructions_item.setStatus,qd_instructions_item.setStatus) ||
                                                    }else if(!this.identical(hd_instructions_item.setStatus,qd_instructions_item.setStatus) || hd_instructions_item.state != qd_instructions_item.state){
                                                        qd_instructions[i] = {...qd_instructions_item,jsiId:hd_instructions_item['jsiId'],intention:'修改'}
                                                        continue innerloop;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                innerloop:
                                for(let i = 0; i<hd_instructions.length; i++){
                                    let hd_instructions_item = hd_instructions[i];
                                    for(let j = 0; j<qd_instructions.length; j++){
                                        let qd_instructions_item = qd_instructions[j];
                                        console.log(qd_instructions,j,'qd_instructions321')
                                        console.log(qd_instructions_item,'qd_instructions_item321')
                                        if( hd_instructions_item.type == qd_instructions_item.type){
                                            if(qd_instructions_item.type == 1){
                                                if(qd_instructions_item.sceneId == hd_instructions_item.sceneId){
                                                    continue innerloop;
                                                }
                                            }else if(qd_instructions_item.type == 2){
                                                if(qd_instructions_item.deviceId == hd_instructions_item.deviceId){
                                                    continue innerloop;
                                                }
                                            }
                                        }
                                    }
                                    qd_instructions.push({...hd_instructions_item,intention:'删除'})
                                }
                        })
                    });
                }
                if(this.contrast.group.length > deepCloneGroup.length){//后端群组长度大于前端群组长度
                    let sliceIndex = this.contrast.group.length - deepCloneGroup.length;  //计算要截取后端群组进行遍历全加删除状态
                    let addGroup = this.contrast.group.slice(sliceIndex)
                    addGroup.forEach((item1,index1)=>{
                        let hd_conditions = item1.conditions.map(item=>{
                            console.log(item)
                            if(item.type == 1){
                                return {
                                    intention:'删除',
                                    type:item['type'],
                                    jlcId:item['jlcId'],
                                    startTime:tiemSplit(item['startTime']),
                                    endTime:tiemSplit(item['endTime']),
                                }
                            }else if(item.type == 2){
                                return {
                                    intention:'删除',
                                    type:item['type'],
                                    jlcId:item['jlcId'],
                                    startDate:item['startDate'],
                                    endDate:item['endDate'],
                                }
                            }else if(item.type == 3){
                                return {
                                    intention:'删除',
                                    type:item['type'],
                                    jlcId:item['jlcId'],
                                    deviceId:item['deviceId'],
                                    // setStatus:item['setStatus'],
                                }
                            }
                        })
                        let hd_instructions = item1.instructions.map(instructionsItem=>{
                            if(instructionsItem.type == 1){
                                return {
                                    intention:'删除',
                                    type:instructionsItem['type'],
                                    sceneId:instructionsItem['sceneId'],
                                    sceneName:instructionsItem['sceneName'],
                                }
                            }else if(instructionsItem.type == 2){
                                return {
                                    intention:'删除',
                                    type:instructionsItem['type'],
                                    devName:instructionsItem['devName'],
                                    deviceId:instructionsItem['deviceId'],
                                    jsiId:instructionsItem['jsiId'],
                                    state:instructionsItem['state'],
                                    // setStatus:instructionsItem['setStatus'],
                                }
                            }
                        })
                        deepCloneGroup.push({conditions:hd_conditions,instructions:hd_instructions})
                    })
                }
                console.log(mechanisms,deepCloneGroup)
                // console.log('set测试提交')
                this.addSubmit(mechanisms,deepCloneGroup)
            }else{
                let [hhh,cloneGroup] = [this.mechanisms.map(e=>{
                    let obj = JSON.parse(JSON.stringify(e));
                    delete obj.deviceFunctionState;
                    return obj;
                }),deepCloneGroup.map(e=>{
                    return {
                        ...e,
                        conditions:e.conditions.map(element=>{
                            let obj = JSON.parse(JSON.stringify(element));
                            delete obj.deviceFunctionState;
                            return obj;
                        })
                    }
                })]
                console.log('提交:',hhh,cloneGroup)
                // console.log('add测试提交')
                this.addSubmit(hhh,cloneGroup)
                // this.addSubmit(this.mechanisms,deepCloneGroup) //oldCode
            }
        },
        // ----------------------
        // identical(obj1,obj2){
        //     console.log(obj1,obj2)
        //     let flag = true;
        //     for(let i in obj1){
        //         for(let j in obj2){
        //             if(i == j){
        //                 if(obj1[i] != obj2[j]){
        //                     console.log('jinlai')
        //                     return false
        //                 }
        //             }
        //         }
        //     }
        //     return flag
        // },
        identical(a, b) {
            // console.log(a,b);
            a=a?a:{};b=b?b:{};
            var aProps = Object.getOwnPropertyNames(a);
            var bProps = Object.getOwnPropertyNames(b);
            if (aProps.length != bProps.length) {
                return false;
            }
            for (var i = 0; i < aProps.length; i++) {
                var propName = aProps[i]

                var propA = a[propName]
                var propB = b[propName]
                if ((typeof (propA) === 'object')) {
                if (this.isObjectValueEqual(propA, propB)) {
                    return true
                    } else {
                    return false
                    }
                } else if (propA !== propB) {
                return false
                } else { }
            }
            return true
        },
        // ----------------------
        addSubmit(mechanisms,group){
            let a = this.$message({
              message: '提交中...',
              type: 'warning',
              duration: 0,
            });
            
            linkageKeep({is:this.is},{
                name:this.linkagePanle.linkageNmae,
                hsId:this.selectAddress.selectAddressRow.hsId,
                jlId:this.jlId||'',
                mechanisms,
                group,
            }).then((res) => {
              a.message = '提交成功';
              a.type = 'success';
              let time = setInterval(() => {
                a.close();
                this.$emit('submitLinkage')
                clearTimeout(time);
              }, 2000);
            }).catch((err) => {
                a.close();
                this.isError(err, this);
            });
        },
        // 触发机制 新增保持修改
        changeIntention(arr1,arr2){
            console.log(arr1,arr2,'arr1,arr2')
            let arr = []
            outer:
            for(let j = 0; j < arr2.length; j++){
                console.log(arr2,'arr2')
                 let item2 = arr2[j];
                 if(item2.intention=="新增" && item2.jldId ==""){
                     console.log('新增')
                    arr2[j] = {...item2,intention:'新增'}
                    arr.push({...item2,intention:'新增'})
                    // continue outer;
                 }else{
                    for(let i = 0; i<arr1.length; i++){
                    // console.log(arr1,'保持修改')
                    let item1 = arr1[i]
                    if(item1.type == item2.type){
                        if(item1.type === 1 && item1.subType == item2.subType){
                            if(item1.subType == 1){
                                //  console.log('单次 修改')
                                if(
                                item1.time == item2.time &&
                                item1.onceDate == item2.onceDate 
                                && item1.jltType == item2.jltType
                                && item1.jltVariable == item2.jltVariable
                                ){
                                    // console.log('保持 一样')
                                    arr1[i] = {...item1,intention:'保持'}
                                    arr2[j] = {...item2,jltId:item1.jltId,intention:'保持'}
                                    arr.push({...item2,jltId:item1.jltId,intention:'保持'})
                                }else{
                                    // console.log('修改 不一样')
                                    arr1[i] = {...item1,intention:'修改'}
                                    arr2[j] = {...item2,jltId:item1.jltId,intention:'修改'}
                                    arr.push({...item2,jltId:item1.jltId,intention:'修改'})
                                }
                            }else if(item1.subType == 2){
                                // console.log('周期 修改')
                                if(
                                item1.time == item2.time &&
                                item1.weekDays == item2.weekDays 
                                && item1.jltType == item2.jltType
                                && item1.jltVariable == item2.jltVariable
                                ){
                                    // console.log('保持 一样')
                                    arr1[i] = {...item1,intention:'保持'}
                                    arr2[j] = {...item2,jltId:item1.jltId,intention:'保持'}
                                    arr.push({...item2,jltId:item1.jltId,intention:'保持'})
                                }
                                else{
                                    // console.log('修改 不一样')
                                    arr1[i] = {...item1,intention:'修改'}
                                    arr2[j] = {...item2,jltId:item1.jltId,intention:'修改'}
                                    arr.push({...item2,jltId:item1.jltId,intention:'修改'})
                                }
                            }
                        }else if(item1.type == 2){
                            if( 
                              item1.deviceId == item2.deviceId 
                              && item1.englishName == item2.englishName 
                              && item1.functionList == item2.functionList 
                              && item1.compare == item2.compare 
                            ){
                            // console.log('type2 保持')
                            arr1[i] = {...item1,intention:'保持'}
                            arr2[j] = {...item2,jldId:item1.jldId,intention:'保持'}
                            arr.push({...item2,jldId:item1.jldId,intention:'保持'})
                           }else 
                           {
                            // console.log( 'type2 修改')
                            arr1[i] = {...item1,intention:'修改'}
                            arr2[j] = {...item2,jldId:item1.jldId,intention:'修改'}
                            arr.push({...item2,jldId:item1.jldId,intention:'修改'})
                          }
                        }
                      }  
                    }
                }
            }
            // console.log(arr,'arr打印')
            return arr
        },
        // 触发机制 删除
        deleteIntention(arr1){
            console.log(arr1)
            // console.log('触发机制删除')
            let arr = []
            for(let i = 0; i < arr1.length; i++){
                let item1 = arr1[i];
                if(!item1.intention){
                    arr.push({...item1,intention:'删除'})
                }
            }
            // console.log(arr,'打印 删除')
            return arr
        },
        // 触发条件 删除
        deleteConditions(arr1){
           // console.log(arr1,'触发条件删除')
           let arr = []
           for(let i = 0; i < arr1.length; i++){
               let item1 = arr1[i];
               if(!item1.intention){
                   arr.push({...item1,intention:'删除'})
               }
           }
           // console.log(arr,'打印 删除')
            return arr
        },
        // 判断时间触发&设备触发 添加重复
        isCheckDuplicate(checkDuplicate){
             console.log(checkDuplicate,'checkDuplicate')
            for (let i = 0; i < checkDuplicate.length - 1; i++) {
                for (let j = i+1; j < checkDuplicate.length; j++) {
                    if (checkDuplicate[i].type == checkDuplicate[j].type) {
                        if(checkDuplicate[i].type == 1 && checkDuplicate[i].subType == checkDuplicate[j].subType){
                            if(checkDuplicate[i].subType == 1){
                                if(checkDuplicate[i].onceDate == checkDuplicate[j].onceDate 
                                && checkDuplicate[i].time == checkDuplicate[j].time 
                                && checkDuplicate[i].jltType == checkDuplicate[j].jltType 
                                && checkDuplicate[i].jltVariable == checkDuplicate[j].jltVariable){
                                    console.log(checkDuplicate[i] , checkDuplicate[j],'判断重复 单次')
                                    return true
                                }
                            }else if(checkDuplicate[i].subType == 2){
                                if( checkDuplicate[i].weekDays == checkDuplicate[j].weekDays
                                && checkDuplicate[i].time == checkDuplicate[j].time
                                && checkDuplicate[i].jltType == checkDuplicate[j].jltType 
                                && checkDuplicate[i].jltVariable == checkDuplicate[j].jltVariable){
                                    console.log(checkDuplicate[i] , checkDuplicate[j],'判断重复 多次')
                                    return true
                                }
                            }
                        }
                        // 设备触发 判重
                        if(checkDuplicate[i].type == 2){
                            // if(checkDuplicate[i].deviceId == checkDuplicate[j].deviceId && checkDuplicate[i].aisle == checkDuplicate[j].aisle){
                            //     console.log(checkDuplicate[i] , checkDuplicate[j])
                            //     return true
                            // }
                        }
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>