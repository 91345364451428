<template>
  <div>
      <el-row style="padding: 5px 5px 5px 0" class="text-weight">
        安装地址：<el-input size="mini" v-model="form.params.room" @click.native="seleceRoom" readonly style="width: 15%" ></el-input>
        <el-dialog v-dialogDrag title="选择其他房间" :visible.sync="installationAddress" :close-on-click-modal="false" @close="installationAddress = false" width="800px" append-to-body>
            <InstallationAddress ref="installationAddress"  v-if="installationAddress" :hsIds="this.form.params.hsIds" :isDuoXuan="isDuoXuan=true"/>
            <div slot="footer">
                <el-button @click="installastionAddress = false">取 消</el-button>
                <el-button type="primary" @click="confirmSelece">搜 索</el-button>
            </div>
        </el-dialog>
        场景位置：<el-input size="mini" v-model="form.params.devNickname" @change="nameChange" clearable style="width: 15%"></el-input>
        场景模式：<el-input size="mini" v-model="form.params.devAuthSecret" @change="devAuthSecretChange" clearable style="width: 15%"></el-input>
      </el-row>
      <el-table ref="multipleTable" v-loading="standSceneListData.loading" :data="standSceneListData.sceneList" tooltip-effect="dark" style="width: 100%" height="400" highlight-current-row @selection-change="allSelection" @row-dblclick="dbPitchOn">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="场景位置" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
                <span>{{ scope.row.hsAddCommunity }} {{ scope.row.hsAddDoorplateno }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="jscName" label="场景模式" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="添加状态" align="center" :filters="[ { text: '已添加', value: '已添加' }, { text: '未添加', value: '未添加' }, ]" :filter-method="filterTag" filter-placement="bottom-end">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == '已添加' ? 'primary' : 'success'" disable-transitions >{{ scope.row.status }}</el-tag >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;text-align:right">
        <el-button @click="standSceneListData.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDev">添 加</el-button>
      </div>
  </div>
</template>
<script>
import { linkageGetLinkageScene } from '@/api/scene';
import InstallationAddress from '@/components/equipmentChild/installationAddress.vue'
export default {
  props: {
    standSceneListData: Object,
  },
  components:{
    InstallationAddress
  },
  data() {
    return {
      installationAddress:false,
      selectedDev: [],
      form: {
        //设备列表数据
        params: {
          devNickname: '',
          devSn: '',
          devAuthSecret: '',
          hsIds:[],
          status: '',
          room:'选择了1个房间',
        },
      },

    };
  },
  watch: {
    'standSceneListData.sceneList': function() {
      this.$nextTick(function() {
        this.detectionRedDev();
      });
    },
    'standSceneListData.dialogVisible': function() {
      if (this.standSceneListData.dialogVisible) {
        this.$nextTick(function() {
          this.$refs.multipleTable.clearSelection();
          this.detectionRedDev();
        });
      }
    },
    'form.params.hsIds':{
      handler(newName, oldName) {
        console.log(newName , oldName);
        if(newName.length == oldName.length){return}
        this.form.params.room = `${'选择了'+newName.length + '个房间'}`
      },
    }
  },
  created(){
    this.form.params.hsIds = [this.standSceneListData.hsId]
  },
  methods: {
    confirmSelece(){
      this.installationAddress = false;
      console.log(this.standSceneListData.hsId)

      this.form.params.hsIds = [this.standSceneListData.hsId]
      this.$refs.installationAddress.multipleSelection.forEach(item => {
        this.form.params.hsIds.push(item.hsId);
      });

      this.form.params.hsIds = Array.from(new Set(this.form.params.hsIds))
      this.getStandSceneList()
    },
    seleceRoom(){
      if(this.form.params.hsIds.length < 1){
        this.form.params.hsIds = [this.standSceneListData.hsId]
      }
      this.installationAddress = true;
    },
    filterTag(value, row) {
      return row.status == value;
    },

    dbPitchOn(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row);
      
    },
    allSelection(val) {
      this.selectedDev = val;
      console.log(this.selectedDev);
    },
    addDev() {
      console.log('87878787')
      // console.log(this.standSceneListData.dialogVisible);
      this.standSceneListData.dialogVisible = false;
      console.log("selectedDev:",this.selectedDev);
      this.$emit('func', this.selectedDev);
    },
    detectionRedDev() {
      let arr1 = this.standSceneListData.devTable;
      let arr2 = this.standSceneListData.sceneList;
      for (let i = 0; i < arr2.length; i++) {
        arr2[i].status = '未添加';
        for (let j = 0; j < arr1.length; j++) {
            console.log(arr1[j],arr2[i]);
          if (arr1[j].sceneId == arr2[i].jsId) {
            this.$refs.multipleTable.toggleRowSelection(arr2[i]);
            arr2[i].status = '已添加';
          }
        }
      }
    },
    nameChange() {
      //设备名称查询
      this.getStandSceneList();
    },
    devAuthSecretChange() {
      //设备网关查询
      this.getStandSceneList();
    },
    devSnChange() {
      //设备SN查询
      this.getStandSceneList();
    },
    getStandSceneList() {
      this.standSceneListData.loading = true;
      if(this.form.params.hsIds<1){
        this.form.params.hsIds = [this.standSceneListData.hsId]
      }
      linkageGetLinkageScene({
          sceneName:this.form.params.devAuthSecret,
          sceneAddress:this.form.params.devNickname,
          hsIds:this.form.params.hsIds,
          })
      .then((res) => {
          this.standSceneListData.sceneList = res.data.result;
          if (this.standSceneListData.sceneList.length < 1) {this.standSceneListData.loading = false;this.$message.warning('此场景没有设备');return}
          this.standSceneListData.loading = false;
      }).catch((err) => {
          this.isError(err, this);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
