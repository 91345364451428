<template>
    <el-row>
        <el-col :span="4" align="left">
            执行动作：设备动作
        </el-col>
        <el-col :span="6" align="left">
            设备名称：{{ items.devName }}
        </el-col> 
        <el-col :span="5" v-if="items.deviceId" align="left">
          <el-switch
            v-model="items.state"
            :active-value="true"
            :inactive-value="false"
            active-text="开"
            inactive-text="关"
            class="switchStyle"
            active-color="#00A854"
            inactive-color="#F04134"
          ></el-switch>
        </el-col>
        <el-col :span="5" align="left" v-if="items.deviceId">
          <el-button
            :disabled="items.state === false"
            size="small"
            type="warning"
            @click="equipmentOperation"
            v-show="
              airConditioner.secondType.indexOf(devSecondType) > -1 ||
              coolingAndHeatingLamp.secondType.indexOf(devSecondType) > -1 ||
              lnfraredAlarm.secondType.indexOf(devSecondType) > -1 ||
              adjustingLamp.secondType.indexOf(devSecondType) > -1 ||
              underfloorHeatingLamp.secondType.indexOf(devSecondType) > -1 ||
              percentageCurtain.secondType.indexOf(devSecondType) > -1
            "
            >详细设置</el-button
          >
            <el-dialog title="详细设置" :visible.sync="dialogCaoZuoSheBei" width="500px" :close-on-click-modal="false" append-to-body >
              <EquipmentList
                @closeEquipmentList="getEquipmentOperation($event)"
                v-if="dialogCaoZuoSheBei"
                :isDialogCaoZuoSheBei="dialogCaoZuoSheBei"
                :isDevSecondType="devSecondType"
                :isDevFirstType="devFirstType"
                :panelFormRow="items.setStatus"
            ></EquipmentList>
          </el-dialog>
        </el-col>
    </el-row>
</template>
<script>
import EquipmentList from '@/views/equipmentMatters/components/sceneManagement/addSeneComponents/equipmentList.vue'
export default {
    props:{
        actionDev:Array,
        items:Object,
    },
    components:{
        EquipmentList,
    },
    data() {
        return {
            devFirstType:this.items.fs,
            devSecondType:this.items.st,
            dialogCaoZuoSheBei:false,
            airConditioner: {
              //空调
              secondType: [4, 42, 43, 49],
              mode: {
                temperature: '',
                pattern: '',
                windSpeed: '',
              },
            },
            coolingAndHeatingLamp: {
              //冷暖灯
              secondType: [31, 44, 56, 76, 97],
              mode: {
                brightness: 1,
                colorTemperature: 1,
              },
            },
            adjustingLamp: {
              //调节灯
              secondType: [36, 59],
              mode: {
                brightness: 1,
              },
            },
            underfloorHeatingLamp: {
              //地暖
              secondType: [57],
              mode: {
                brightness: 0,
              },
            },

            lnfraredAlarm: {
              // 红外
              secondType: [93, 95, 106],
              mode: {
                devId: '',
                switchs: '',
                spacing: '',
                threshold: '',
                sensitivity: '',
                periodVaule: '',
                thresholdVaule: '',
                sensitivityVaule: '',
              },
            },
            //窗帘
            percentageCurtain: {
              secondType: [117, 108],
              mode: {
                percentCurtain: 0,
              },
            },
        }
    },
    //计算属性
    computed:{

    },
    //侦听器
    watch:{

    },
    //初始化生命周期
    created() {
        
    },
    //方法
    methods: {
        equipmentOperation() {
            this.dialogCaoZuoSheBei = true;
            console.log(this.devSecondType,this.items.setStatus)
            this.getDeviceDetail(this.items.setStatus,this.devSecondType)
        },
        getEquipmentOperation(data){
            this.dialogCaoZuoSheBei = false;
            this.items.setStatus = data;
        },
        getDeviceDetail(row,devSecondType){
            if (this.airConditioner.secondType.indexOf(devSecondType) > -1) {
                //空调详细参数
                for (let i in row) {
                    for (let j in this.airConditioner.mode) {
                        if (i == j) {
                            this.airConditioner.mode[i] = row[j];
                        }
                    }
                }
            } else if (
                this.coolingAndHeatingLamp.secondType.indexOf(devSecondType) > -1
            ) {
                //冷暖灯详细参数
                for (let i in row) {
                    for (let j in this.coolingAndHeatingLamp.mode) {
                        if (i == j) {
                        this.coolingAndHeatingLamp.mode[i] = Number(row[j]);
                            console.log(this.coolingAndHeatingLamp,row);
                        }
                    }
                }
            } else if (
              this.adjustingLamp.secondType.indexOf(devSecondType) > -1
            ) {
                //调节灯详细参数
                for (let i in row) {
                    for (let j in this.adjustingLamp.mode) {
                        if (i == j) {
                        this.adjustingLamp.mode[i] = Number(row[j]);
                        }
                    }
                }
            } else if (
                this.lnfraredAlarm.secondType.indexOf(devSecondType) > -1
            ) {
                //红外
                for (let i in row) {
                    for (let j in this.lnfraredAlarm.mode) {
                        if (i == j) {
                            this.lnfraredAlarm.mode[i] = Number(row[j]);
                        }
                    }
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>