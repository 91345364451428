<template>
    <el-row>
        <el-col :span="4">
        触发条件：日期条件
        </el-col>
        <el-col :span="20" align="left">
        开始日期：
        <el-date-picker
            v-model="items.startDate"
            type="date"
            size="mini"
            style="width:200px;"
            value-format="yyyy-MM-dd"
            placeholder="请选择开始日期"
            @change="conditionStartDate($event)"
            :picker-options="pickerOptionsStartDate"
            >
        </el-date-picker>
        至
        <el-date-picker
            v-model="items.endDate"
            type="date"
            size="mini"
            style="width:200px;"
            value-format="yyyy-MM-dd"
            placeholder="请选择结束日期"
            @change="conditionEndDate($event)"
            :picker-options="pickerOptionsEndDate"
            >
        </el-date-picker>
        结束日期
      </el-col>
    </el-row>
</template>
<script>

export default {
    components:{
    },
    props:{
        items:Object,
        index:String | Number,
        groupIndex:String | Number,
    },
    data() {
        return {
        }
    },
    //计算属性
    computed:{
        pickerOptionsStartDate(){
            let that = this;
            return {
                disabledDate(time){
                    if (that.items.endDate) {
                      return (time.getTime() > new Date(that.items.endDate).getTime() || time.getTime() < Date.now() - 8.64e7)
                    }
                    return time.getTime() < Date.now() - 8.64e7
                }
            }
        },
        pickerOptionsEndDate(){
            let that = this
            return {
                disabledDate(time){
                    if (that.items.startDate) {
                      return (time.getTime() < new Date(that.items.startDate).getTime())
                    }
                    return time.getTime() < Date.now() - 8.64e7
                }
            }
        }
    },  
    //侦听器
    watch:{

    },
    //初始化生命周期
    created() {

    },
    //方法
    methods: {
        conditionStartDate(value){
            // console.log(value,'开始时间');
        },
        conditionEndDate(value){
            // console.log(value,'结束时间');
        }
    },
}
</script>

<style lang="scss" scoped>

</style>